import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { showError } from '@utils';
import { configurationServices } from '@services';
import { QUERY_KEYS } from 'constants/queryKeys';
import { handleConfigurationData } from './utils';

export const useConfigurationsOperations = () => {
  const configurationResponse = useQuery(
    QUERY_KEYS.GET_BILLING_ASSIGNEES_KEY,
    () => configurationServices.getConfigurations()
  );

  useEffect(() => {
    if (configurationResponse?.error) {
      showError(configurationResponse?.error);
    }
  }, [configurationResponse?.error]);

  const configuration = useMemo(() => {
    return handleConfigurationData(configurationResponse?.data?.data);
  }, [configurationResponse?.data?.data]);

  return {
    loading: configurationResponse?.isLoading,
    error: configurationResponse?.error,
    configuration,
    hasErrors: configurationResponse?.isError,
    errorMessage: configurationResponse?.error,
    refresh: configurationResponse.refetch,
  };
};
