import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectInspectionedCarsState,
  getInspectionedCars,
  getMoreInspectionedCars,
  inspectionChangePage,
  inspectionInitState,
} from '@store';
import { getCleanedUrlParams, showError } from '@utils';

export const useInspectionCarsOperations = (inspectionType, fetchOnMount) => {
  const {
    total,
    loading,
    vehicles,
    hasErrors,
    errorMessage,
    page,
    pageCount,
    pageSize,
    groups,
  } = useSelector(selectInspectionedCarsState);
  const {
    query: { location, type, query, showNotInspected },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _computePayload = ({
    currentPage,
    currentLocation,
    currentType,
    currentQuery,
    currentShowNotInspected,
  }) => {
    return getCleanedUrlParams({
      page: currentPage,
      pageSize,
      order: 'desc',
      orderBy: `inspectionDate`,
      location: currentLocation,
      type: currentType >= 0 ? Number(currentType) : undefined,
      query: currentQuery,
      showInspected: currentShowNotInspected,
    });
  };

  const _getInspectionedCars = useCallback(async () => {
    const payload = _computePayload({
      currentPage: page,
      currentLocation: location,
      currentType: type,
      currentQuery: query,
      currentShowNotInspected: showNotInspected,
    });

    await dispatch(getInspectionedCars(payload));
  }, [location, type, showNotInspected, query, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const _getMoreInspectionedCars = useCallback(
    async (currentPage) => {
      const payload = _computePayload({
        currentPage,
        currentLocation: location,
        currentType: type,
        currentQuery: query,
        currentShowNotInspected: showNotInspected,
      });

      await dispatch(getMoreInspectionedCars(payload));
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const fetchMore = () => {
    const nextPage = page + 1;

    if (pageCount >= nextPage) {
      _getMoreInspectionedCars(nextPage);
    }
  };

  useEffect(() => {
    return () => dispatch(inspectionInitState());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getInspectionedCars();
    }
  }, [_getInspectionedCars, fetchOnMount, isReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    total,
    loading,
    vehicles,
    hasErrors,
    errorMessage,
    page,
    inspectionType,
    pageSize,
    groups,
    getInspectionedCars: _getInspectionedCars,
    fetchMore,
    inspectionChangePage,
  };
};
