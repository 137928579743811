import { RESERVATIONS_TYPES } from '@constants';
import { formatDate, sortArray } from '@utils';

export const handleReservationData = (data) => {
  return {
    ...data,
    createdAt: data.createdAt ? formatDate(data.createdAt, 'DD/MM/YYYY') : '-',
    updatedAt: data.updatedAt ? formatDate(data.updatedAt, 'DD/MM/YYYY') : '-',
    comments: sortArray(data?.comments, 'createdAt', 'desc')?.map((item) => {
      return {
        ...item,
        date: item.createdAt
          ? formatDate(item.createdAt, 'DD/MM/YYYY hh:mm')
          : '-',
        time: ' ',
        name: item?.createdBy,
        comment: item?.message,
      };
    }),
  };
};

export const handleReservationHistory = (data) => {
  if (!data) {
    return [];
  }
  const history = data
    ?.map((item) => {
      const isType = item?.field?.[0] === 'Type';

      if (item?.field?.[0] === 'Comments') return null;

      if (isType) {
        const oldValue = Object.values(RESERVATIONS_TYPES).find(
          (type) => type.value === item?.from
        )?.text;
        const newValue = Object.values(RESERVATIONS_TYPES).find(
          (type) => type.value === item?.to
        )?.text;

        return {
          ...item,
          createdBy: item?.actor,
          fieldName: item?.field?.[0] || '',
          newValue,
          oldValue,
        };
      }

      return {
        ...item,
        createdBy: item?.actor,
        fieldName: item?.field?.[0] || '',
        newValue: item?.to || '',
        oldValue: item?.from || '-',
      };
    })
    ?.filter((item) => item);
  return history;
};
