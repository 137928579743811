import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getCleanedUrlParams, getUrl, showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { productsServices } from '@services';
import { URLS } from 'layouts/DrawerLayout/constants';
import { getProductsQueryParams, normalizeProducts } from './utils';
import { PRODUCTS_PAGE_SIZE } from './constants';

export const useProductsOperations = (enableFetch = true) => {
  const router = useRouter();
  const pageSize = PRODUCTS_PAGE_SIZE;

  const page = router.query?.page || 1;
  const orderBy = router.query?.orderBy;
  const order = router.query?.order || 'asc';

  const params = {
    orderBy,
    order,
    page,
  };

  const cleanParams = getProductsQueryParams(params);

  const productsResponse = useQuery(
    [QUERY_KEYS.GET_PRODUCTS_KEY, cleanParams],
    () => productsServices.getProducts(getCleanedUrlParams(params)),
    { enabled: enableFetch, refetchOnMount: true }
  );

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...router.query, ...sortByModel }, URLS.products);
    router.push(url);
  };

  const handlePageChange = (_event, newPage) => {
    router.push(getUrl({ ...router.query, page: newPage }), URLS.products);
  };

  const products = normalizeProducts(productsResponse?.data?.data || []);

  useEffect(() => {
    if (productsResponse.isError) {
      showError(productsResponse?.error);
    }
  }, [productsResponse.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    page,
    pageSize,
    total: productsResponse?.data?.meta?.total,
    loading: productsResponse.isLoading,
    products,
    hasErrors: productsResponse?.isError,
    errorMessage: productsResponse?.error,
    getProducts: productsResponse.refetch,
    nextPage: handlePageChange,
    sortBy: handleSortBy,
  };
};
