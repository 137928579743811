import { generateShortId } from '@utils';

export const INITIAL_VALUES = {
  skuId: '',
  type: '',
  name: '',
  properties: {
    brand: '',
    brandSlug: '',
    model: '',
    modelSlug: '',
    edition: '',
    year: '',
    engine: {
      displacement: '',
      powerHp: 250,
      fuelType: '',
      type: '',
      fuelEconomy: '',
      emissions: '',
      fuelCapacity: '',
      topSpeed: '',
      torque: '',
      euroClass: '',
      autonomy: '',
      speed: '',
      batterySize: '',
      tireSize: '',
      batteryChargeTime: '',
      acceleration: '',
      numberOfGears: '',
    },
    body: {
      type: '',
      size: '',
      seatsNumber: '',
      doorsNumber: '',
      airbagsNumber: '',
      height: '',
      weight: '',
      interiorType: '',
      trunkCapacity: '',
      length: '',
      width: '',
    },
    transmission: '',
    category: '',
    productLine: '',
    tireSize: '',
    equipment: {
      comfort: {
        'el-GR': '',
        'en-US': '',
      },
      exterior: {
        'el-GR': '',
        'en-US': '',
      },
      interior: {
        'el-GR': '',
        'en-US': '',
      },
      multimedia: {
        'el-GR': '',
        'en-US': '',
      },
      safety: {
        'el-GR': '',
        'en-US': '',
      },
    },
  },
  images: [],
  docs: [],
  pricing: [
    {
      id: generateShortId(),
      type: 0, // open
      monthsDuration: null,
      signUpFee: null,
      price: '0.00',
      initialPrice: '0.00',
    },
    {
      id: generateShortId(),
      type: 1, // fixed
      monthsDuration: 3,
      signUpFee: null,
      price: '0.00',
      initialPrice: '0.00',
    },
    {
      id: generateShortId(),
      type: 1, // fixed
      monthsDuration: 6,
      signUpFee: null,
      price: '0.00',
      initialPrice: '0.00',
    },
  ],
  instastart: '',
  signUpFee: '',
  pricingWithVat: {
    vatApplied: '',
    price: '',
    instastart: '',
    signUpFee: '',
    initialPrice: '',
  },
  monthlyKilometers: '',
  tags: [],
  published: false,
  colors: [],
  vendors: [],
  availability: -1,
  pricePerKm: '',
  chargePerExtraKm: '',
  retailPrice: '',
  instacarPrice: '',
  videoUrl: '',
  extras: [],
  additionalInfo: {
    'el-GR': '',
    'en-US': '',
  },
  selectedImageId: '',
  comments: '',
  cities: [],
  createdAt: '',
  createdBy: '',
  updatedAt: '',
  updatedBy: '',
};
