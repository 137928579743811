import dynamic from 'next/dynamic';

const ArnmAccidentsGrid = dynamic(() =>
  import('./ArnmAccidentsGrid/ArnmAccidentsGrid')
);
const AddArnmAccidentsModal = dynamic(() =>
  import('./AddArnmAccidentsModal/AddArnmAccidentsModal')
);
const UploadArnmAccidentsDocument = dynamic(() =>
  import('./UploadArnmAccidentsDocument/UploadArnmAccidentsDocument')
);
const ArnmAccidentsVehicleHeader = dynamic(() =>
  import('./ArnmAccidentsVehicleHeader/ArnmAccidentsVehicleHeader')
);
const ChangeArnmAccidentsStage = dynamic(() =>
  import('./ChangeArnmAccidentsStage/ChangeArnmAccidentsStage')
);
const ArnmAccidentsFilter = dynamic(() =>
  import('./ArnmAccidentsFilter/ArnmAccidentsFilter')
);

export {
  ArnmAccidentsGrid,
  AddArnmAccidentsModal,
  UploadArnmAccidentsDocument,
  ArnmAccidentsVehicleHeader,
  ChangeArnmAccidentsStage,
  ArnmAccidentsFilter,
};
