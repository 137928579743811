import { request } from '@utils';

const getProducts = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = 1000;
  }
  const results = await request.get(`/v1/marketplace/products`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getProductById = async (productId) => {
  const results = await request.get(`/v1/marketplace/products/${productId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateProduct = async (productId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/marketplace/products/${productId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { updateProduct, getProducts, getProductById };
