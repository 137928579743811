import dynamic from 'next/dynamic';

const NotificationsContent = dynamic(() =>
  import('./NotificationsContent/NotificationsContent')
);
const NotificationsDrawer = dynamic(() =>
  import('./NotificationsDrawer/NotificationsDrawer')
);

const NotificationsActions = dynamic(() =>
  import('./NotificationsActions/NotificationsActions')
);

export { NotificationsContent, NotificationsDrawer, NotificationsActions };
