import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoadsideAssistancesState, getRoadsideAssistance } from '@store';
import { showError } from '@utils';

export const useRoadsideAssistanceOperations = () => {
  const { total, loading, roadsideAssistance, hasErrors, errorMessage } =
    useSelector(selectRoadsideAssistancesState);
  const {
    query: { roadsideAssistanceId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getRoadsideAssistance = useCallback(async () => {
    await dispatch(getRoadsideAssistance(roadsideAssistanceId));
  }, [roadsideAssistanceId, dispatch]);

  useEffect(() => {
    if (roadsideAssistanceId !== undefined) {
      _getRoadsideAssistance();
    }
  }, [roadsideAssistanceId, _getRoadsideAssistance, token]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    total,
    loading,
    roadsideAssistance,
    roadsideAssistanceId,
    hasErrors,
    errorMessage,
    getRoadsideAssistance: _getRoadsideAssistance,
    token,
  };
};
