/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFleetVehicleState,
  getFleetVehicle,
  initialize,
  getFleetVehicleInsurances,
  getFleetVehicleRoadsideAssistance,
  getFleetVehicleColor,
  getFleetVehiclePlates,
  getFleetVehicleServicePoints,
  getFleetVehicleServicePointsAll,
  getFleetVehicleMileages,
  getFleetVehicleDealers,
  getFleetVehicleFinancingProviders,
  getFleetVehicleSourcingTypes,
  getFleetVehicleHistory,
  getFleetVehicleChangelog,
} from '@store';
import { showError } from '@utils';

let renderId = '';

export const useFleetVehicleOperations = (firstMount) => {
  const {
    loading,
    vehicle,
    hasErrors,
    errorMessage,
    colors,
    dealers,
    sourcingTypes,
    changelog,
  } = useSelector(selectFleetVehicleState);
  const {
    back,
    query: { vehicleId },
  } = useRouter();
  const dispatch = useDispatch();

  const _initialize = useCallback(() => {
    dispatch(initialize());
  }, [dispatch]);

  const _getVehicle = useCallback(
    async (payload = {}) => {
      if (vehicleId) {
        await dispatch(getFleetVehicle({ ...payload, vehicleId }));
      }
    },
    [vehicleId, dispatch]
  );

  const _getFleetVehicleChangelog = useCallback(() => {
    if (vehicleId) {
      dispatch(getFleetVehicleChangelog(vehicleId));
    }
  }, [vehicleId, dispatch]);

  const _getFleetVehicleFinancingProviders = useCallback(async () => {
    await dispatch(getFleetVehicleFinancingProviders());
  }, [dispatch]);

  const _getFleetVehicleSourcingTypes = useCallback(async () => {
    await dispatch(getFleetVehicleSourcingTypes());
  }, [dispatch]);

  const _getFleetVehicleDealers = useCallback(async () => {
    await dispatch(getFleetVehicleDealers());
  }, [dispatch]);

  const _getFleetVehicleMileages = useCallback(async () => {
    await dispatch(getFleetVehicleMileages(vehicle?.id));
  }, [dispatch, vehicle?.id]);

  const _getFleetVehicleServicePointsAll = useCallback(async () => {
    await dispatch(getFleetVehicleServicePointsAll());
  }, [dispatch]);

  const _getFleetVehicleServicePoints = useCallback(async () => {
    await dispatch(
      getFleetVehicleServicePoints({ id: vehicle?.id, status: 1 })
    ); // fetch activate
  }, [dispatch, vehicle?.id]);

  const _getFleetVehiclePlates = useCallback(async () => {
    await dispatch(getFleetVehiclePlates(vehicle?.id));
  }, [dispatch, vehicle?.id]);

  const _getFleetVehicleColor = useCallback(async () => {
    await dispatch(getFleetVehicleColor());
  }, [dispatch]);

  const _getFleetVehicleRoadsideAssistance = useCallback(async () => {
    await dispatch(getFleetVehicleRoadsideAssistance());
  }, [dispatch]);

  const _getInsurances = useCallback(async () => {
    await dispatch(getFleetVehicleInsurances());
  }, [dispatch]);

  const _getFleetVehicleHistory = useCallback(async () => {
    if (vehicle?.internalId) {
      await dispatch(getFleetVehicleHistory(vehicle?.internalId));
    }
  }, [dispatch, vehicle?.internalId]);

  useEffect(() => {
    if (renderId && vehicle?.renderId === renderId) return;
    renderId = vehicle?.renderId;
    if (vehicle?.details?.insuranceId?.options?.length === 0) {
      _getInsurances();
    }
    if (vehicle?.details?.roadsideAssistanceId?.options?.length === 0) {
      _getFleetVehicleRoadsideAssistance();
    }
    if (vehicle?.information?.color?.options?.length === 0) {
      _getFleetVehicleColor();
    }
    if (vehicle?.plates?.length === 0 && vehicle?.id) {
      _getFleetVehiclePlates();
    }
    if (vehicle?.servicePoints?.length === 0 && vehicle?.id) {
      _getFleetVehicleServicePoints();
    }
    if (vehicle?.servicePointsAll?.length === 0) {
      _getFleetVehicleServicePointsAll();
    }
    if (vehicle?.mileages?.length === 0 && vehicle?.id) {
      _getFleetVehicleMileages();
    }

    if (vehicle?.dealers?.length === 0) {
      _getFleetVehicleDealers();
    }
    if (vehicle?.financingProviders?.length === 0) {
      _getFleetVehicleFinancingProviders();
    }
    _getFleetVehicleHistory();
  }, [vehicle?.id, vehicle?.renderId]);

  useEffect(() => {
    if (vehicleId && firstMount) {
      _initialize();
      _getVehicle({ loading: true });
    }
  }, [vehicleId, _getVehicle]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
    if (changelog?.errorMessage) {
      showError(changelog?.errorMessage);
    }
  }, [errorMessage, changelog?.errorMessage]);

  return {
    loading,
    vehicleId,
    vehicle,
    hasErrors,
    errorMessage,
    getVehicle: _getVehicle,
    goBack: back,
    colors,
    getFleetVehicleColor: _getFleetVehicleColor,
    dealers,
    getFleetVehicleDealers: _getFleetVehicleDealers,
    sourcingTypes,
    changelog,
    getFleetVehicleSourcingTypes: _getFleetVehicleSourcingTypes,
    getFleetVehicleHistory: _getFleetVehicleHistory,
    getFleetVehicleChangelog: _getFleetVehicleChangelog,
    initialize: _initialize,
  };
};
