import { LIMIT } from '@constants';
import { getCleanedUrlParams } from '@utils';
import { pick } from 'lodash';

export const normalizeTeams = (data) => {
  if (!data?.length > 0) {
    return [];
  }

  const transformedData = data?.map((item) => {
    return {
      id: item?.id,
      logo: item?.logo?.url || '',
      name: item?.name || '-',
      category: '-',
      status: item?.status,
      skus: '',
    };
  });

  return transformedData;
};

export const getVendorsQueryParams = (payload = {}) => {
  const customFields = [];

  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  if (!payload.order) {
    payload.order = 'desc';
    payload.orderBy = 'createdAt';
  }

  Object.keys(payload)?.forEach((key) => {
    if (customFields.includes(key)) {
      payload[`customFields.${key}`] = payload[key];
      delete payload[key];
    }
  });

  return getCleanedUrlParams(
    pick(payload, ['page', 'pageSize', 'order', 'orderBy'])
  );
};
