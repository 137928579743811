/* eslint-disable no-console */
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';

import {
  agentsServices,
  boardsServices,
  fleetVehiclesServices,
} from '@services';
import { asyncForEach, showError } from '@utils';
import { QUERY_KEYS } from 'constants/queryKeys';
import {
  getPayload,
  handleBillingTaskData,
  normaliseAssigneeData,
  normalizeVehicleData,
} from './utils';
import { BILLING_TABS } from './constants';

export const useBillingTaskOperations = () => {
  const router = useRouter();
  const billingTaskId = router.query?.billingTaskId;

  const assigneesResponse = useQuery(QUERY_KEYS.GET_BILLING_ASSIGNEES_KEY, () =>
    agentsServices.getAgents({
      page: 1,
      pageSize: 3000,
    })
  );

  const taskResponse = useQuery(
    [QUERY_KEYS.GET_BILLING_TASK_KEY, billingTaskId],
    () => boardsServices.getTaskById(billingTaskId),
    {
      enabled: assigneesResponse.isFetched,
    }
  );

  const vehicleResponse = useQuery(
    [QUERY_KEYS.GET_VEHICLE_BY_INTERNAL_ID_KEY, billingTaskId],
    () =>
      fleetVehiclesServices.getVehicleByInternalId(
        taskResponse.data.data.customFields?.carId
      ),
    {
      enabled:
        taskResponse.isFetched &&
        !!taskResponse?.data?.data?.customFields?.carId,
    }
  );

  const assignees =
    normaliseAssigneeData(assigneesResponse?.data?.data || []) || [];

  const mentionedUsers = assignees?.map?.((user) => {
    return { id: user?.value, display: user?.text };
  });

  const vehicle = normalizeVehicleData(vehicleResponse?.data?.data || {});
  const loading =
    taskResponse?.isLoading ||
    assigneesResponse?.isLoading ||
    vehicleResponse?.isLoading;

  const updateTask = async ({ values, images, documents }) => {
    try {
      const imagesToupLoad = images?.filter((img) => img?.localFile);
      const documentsToUpLoad = documents?.filter((doc) => doc?.localFile);

      const payload = getPayload(values);

      await boardsServices.updateTask(billingTaskId, payload);

      await asyncForEach(imagesToupLoad, async (img) => {
        await boardsServices.uploadTaskImage(billingTaskId, img?.file);
      });

      await asyncForEach(documentsToUpLoad, async (doc) => {
        await boardsServices.uploadTaskDocument(
          billingTaskId,
          doc?.file,
          '',
          doc?.tags
        );
      });
    } catch (error) {
      showError(error);
    }
  };
  const boardId = billingTaskId?.split('-')?.[0];
  const board =
    Object.keys(BILLING_TABS).find((key) => BILLING_TABS[key].id === boardId) ||
    {};

  const stages = BILLING_TABS[board]?.statusOrder?.reduce((acc, cur) => {
    acc.push({
      text: BILLING_TABS[board].statuses[cur].label,
      value: Number(cur),
      board,
    });
    return acc;
  }, []);

  const boards = Object.keys(BILLING_TABS).reduce((acc, cur) => {
    acc.push({
      id: BILLING_TABS[cur].id,
      text: BILLING_TABS[cur].label,
      value: BILLING_TABS[cur].type,
    });
    return acc;
  }, []);

  return {
    refresh: taskResponse.refetch,
    assignees,
    mentionedUsers,
    loading,
    board,
    stages,
    boards,
    error: taskResponse?.error,
    isSuccess: taskResponse?.isSuccess,
    task: handleBillingTaskData({
      data: taskResponse?.data?.data || [],
      assignees: assignees || [],
      stages: stages || [],
      boards: boards || [],
    }),
    vehicle: loading ? {} : vehicle,
    updateTask,
  };
};
