import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { productsServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';

import { handleProduct } from './products.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  products: [],
  product: {},
  getProducthasErrors: false,
  getProductErrorMessage: '',
  total: 0,
  firstItem: 1,
  lastItem: LIMIT,
  pageCount: 0,
  pageSize: LIMIT,
};
// Actual Slice
export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    fetchProduct: (state) => {
      state.loading = true;
      state.getProducthasErrors = false;
      state.getProductErrorMessage = '';
    },
    fetchProductSuccess: (state, { payload }) => {
      state.product = handleProduct(payload?.data);
      state.loading = false;
    },
    fetchProductFailure: (state, { payload }) => {
      state.loading = false;
      state.getProducthasErrors = true;
      state.getProductErrorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const { fetchProduct, fetchProductSuccess, fetchProductFailure } =
  productsSlice.actions;

export const selectProductsState = (state) => state.products;

export default productsSlice.reducer;

export const getProduct = (productId) => {
  return async (dispatch) => {
    dispatch(fetchProduct());

    try {
      const response = await productsServices?.getProductById(productId);

      dispatch(fetchProductSuccess(response));
    } catch (error) {
      dispatch(fetchProductFailure(error));
    }
  };
};
