import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { marketingServices } from '@services';

export const useCampaignOperations = () => {
  const router = useRouter();
  const campaignId = router.query?.campaignId;

  const campaignResponse = useQuery(
    [QUERY_KEYS.GET_CAMPAIGN_KEY, campaignId],
    () => marketingServices.getCampaign(campaignId),
    { enabled: !!campaignId }
  );

  return {
    campaign: campaignResponse?.data?.data || [],
    campaignId,
  };
};
