/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectBookingsState, getBooking } from '@store';
import { showError } from '@utils';

export const useBookingOperations = (firstMount) => {
  const { loading, booking, getBookinghasErrors, getBookingErrorMessage } =
    useSelector(selectBookingsState);
  const {
    back,
    query: { bookingId },
  } = useRouter();
  const dispatch = useDispatch();

  const _getBooking = useCallback(async () => {
    if (bookingId) {
      await dispatch(getBooking(bookingId));
    }
  }, [bookingId, dispatch]);

  useEffect(() => {
    if (bookingId && firstMount) {
      _getBooking();
    }
  }, [bookingId, _getBooking]);

  useEffect(() => {
    if (getBookingErrorMessage) {
      showError(getBookingErrorMessage);
    }
  }, [getBookingErrorMessage]);

  return {
    loading,
    bookingId,
    booking,
    getBookinghasErrors,
    getBookingErrorMessage,
    getBooking: _getBooking,
    goBack: back,
  };
};
