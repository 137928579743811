import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { agentsServices, teamsServices } from '@services';
import { normalizeAgents, normalizeTeam } from './utils';

export const useTeamOperations = () => {
  const router = useRouter();
  const teamId = router.query?.teamId;

  const agentsResponse = useQuery(
    [QUERY_KEYS.GET_AGENTS_KEY],
    () => agentsServices.getAgents({ pageSize: 1000 }),
    { enabled: true, refetchOnMount: false }
  );

  const teamResponse = useQuery(
    [QUERY_KEYS.GET_TEAM_KEY, teamId],
    () => teamsServices.getTeam(teamId),
    { enabled: !!teamId && agentsResponse.isFetched }
  );

  const agents = normalizeAgents(agentsResponse?.data?.data || []);
  const team = normalizeTeam(teamResponse?.data?.data || []);

  useEffect(() => {
    if (teamResponse.isError) {
      showError(teamResponse?.error);
    }
  }, [teamResponse.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    team,
    teamId,
    agents,
    loading: teamResponse?.isLoading,
    getTeam: teamResponse.refetch,
  };
};
