import { LIMIT } from '@constants';
import { formatDate, getCleanedUrlParams } from '@utils';
import { pick } from 'lodash';

export const normalizeProducts = (data) => {
  if (!data?.length > 0) {
    return [];
  }

  const transformedData = data?.map((item) => {
    return {
      ...item,
      id: item?.id,
      billingFrequency: item?.billingFrequency || '-',
      category: item?.category || '-',
      pos: item?.pos?.length ? item?.pos?.join(', ') : '-',
      vat: item?.vat ? `${item.vat * 100}%` : '-',
      price: item?.price?.number
        ? `${item?.price?.number} ${item?.price?.currency}`
        : '-',
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY HH:mm')
        : '-',
    };
  });

  return transformedData;
};

export const getProductsQueryParams = (payload = {}) => {
  const customFields = [];

  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  if (!payload.order) {
    payload.order = 'desc';
    payload.orderBy = 'createdAt';
  }

  Object.keys(payload)?.forEach((key) => {
    if (customFields.includes(key)) {
      payload[`customFields.${key}`] = payload[key];
      delete payload[key];
    }
  });

  return getCleanedUrlParams(
    pick(payload, ['page', 'pageSize', 'order', 'orderBy'])
  );
};
