import { BOARDS_ID, FILTER_RULE_ENUMS } from '@constants';
import { omit } from 'lodash';

export const getTrafficOffensesPayload = (values = {}) => {
  Object.keys(values.customFields)?.forEach((key) => {
    if (values.customFields[key] === '') {
      values.customFields[key] = null;
    }
  });

  const cleanedValues = omit(values, [
    'assignee',
    'documents',
    'tags',
    'status',
    'updatedAt',
    'updatedBy',
    'changelog',
    'images',
    'parent',
    'name',
    'createdAt',
    'comments',
    'createdBy',
    'subscriptionId',
    'customFields.vehicleDescription',
    'customFields.vehicleImage',
    'customFields.vehicleOwner',
    'customFields.vehiclePlate',
    'customFields.userEmail',
    'customFields.userFirstName',
    'customFields.userId',
    'customFields.userLastName',
    'customFields.vehicleDescription',
    'customFields.vehiclePlate',
    'customFields.vehicleVin',
    'customFields.workOrderId',
    'customFields.sendEmailDocs',
    'customFields.sendEmailPhotos',
    'customFields.sendEmailTemplate',
    'customFields.carId',
  ]);

  return {
    assignee: values.assignee?.value,
    customFields: {
      ...cleanedValues.customFields,
    },
  };
};

export const getTrafficOffensesExportPayload = () => {
  return {
    type: FILTER_RULE_ENUMS.AndRuleType,
    field: null,
    operator: null,
    value: [
      {
        type: FILTER_RULE_ENUMS.ComparableRuleType,
        field: 'boardId',
        operator: 0,
        value: BOARDS_ID.TRAFFIC_OFFENSES,
      },
    ],
  };
};
