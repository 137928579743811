import { omit } from 'lodash';
import { FILTER_OPERATOR_ENUMS, FILTER_RULE_ENUMS } from '@constants';

const BOOLEAN_VALUES = {
  published: true,
  unPublished: false,
};

const FIELDS = {
  brand: 'properties.brand',
  category: 'properties.category',
  status: 'published',
};

export const getCatalogEbikesRequestPayload = (
  payload = {},
  modalFiltersRules = null
) => {
  const fields = omit(payload, ['page', 'pageSize', 'order', 'orderBy']) || {};
  const numberFields = ['category'];
  const booleanFields = ['status'];
  const multyFilterStringFields = [];
  const multyFilterNumberFields = [];

  const _getValue = ({ field }) => {
    if (multyFilterStringFields.includes(field)) {
      return payload[field]?.split(',');
    }
    if (booleanFields.includes(field)) {
      return BOOLEAN_VALUES[payload[field]];
    }
    if (multyFilterNumberFields.includes(field)) {
      return payload[field]?.split(',').map((item) => Number(item));
    }
    return numberFields.includes(field)
      ? Number(payload[field])
      : payload[field];
  };
  const _getType = ({ field }) => {
    if (
      multyFilterStringFields.includes(field) ||
      multyFilterNumberFields.includes(field)
    ) {
      return FILTER_RULE_ENUMS.IsAnyOfRuleType;
    }
    return FILTER_RULE_ENUMS.ComparableRuleType;
  };

  const value = [
    ...Object.keys(fields).map((field) => ({
      field: FIELDS[field],
      operator: FILTER_OPERATOR_ENUMS['='],
      type: _getType({ field }),
      value: _getValue({ field }),
    })),
  ];

  value.push(...(modalFiltersRules?.value || []));

  if (value.length) {
    return {
      type: FILTER_RULE_ENUMS.AndRuleType,
      field: null,
      operator: null,
      value,
    };
  }

  return {
    type: FILTER_RULE_ENUMS.EmptyRuleType,
  };
};
export const computePayloadEbike = (data) => {
  if (data.specialOffer) {
    if (!data.tags.includes('offer')) {
      data.tags.push('offer');
    }
  } else {
    data.tags = data?.tags?.filter((tag) => tag !== 'offer') || [];
  }

  return {
    skuId: `ICB${data?.skuId}`,
    seoFakeVin: data.seoFakeVin,
    properties: {
      brand: data.properties.brandSlug,
      model: data.properties.modelSlug,
      edition: data.properties.edition,
      year: Number(data.properties.year),
      engine: {
        powerHp: Number(data.properties.engine.powerHp),
        fuelType: Number(data.properties.engine.fuelType),
        speed: data.properties.engine.speed
          ? parseInt(data.properties.engine?.speed, 10)
          : 0,
        torque: data.properties.engine.torque
          ? parseInt(data.properties.engine.torque, 10)
          : 0,
        batterySize: data.properties.engine.batterySize,
        numberOfGears: Number(data.properties.engine.numberOfGears),
      },
      body: {
        type: Number(data.properties.body.type),
        size: Number(data.properties.body.size) || null,
        weight: Number(data.properties.body.weight),
      },
      category: Number(data.properties.category),
      productLine: Number(data.properties.productLine),
      tireSize: data.properties.tireSize,
    },
    instastart: data.instastart,
    pricePerKm: data.pricePerKm?.value,
    monthlyKm: Number(data.monthlyKilometers),
    tags: data.tags || [],
    additionalInfo: {
      'el-GR': data.additionalInfo['el-GR'],
      'en-US': data.additionalInfo['en-US'],
    },
    published: data.published,
    vendors: data.vendor ? [data.vendor] : [],
    colors: [],
    cities: null,
    km: null,
    retailPrice: data.retailPrice,
    instacarPrice: data.instacarPrice,
    videoUrl: data.videoUrl,
    specialOffer: data.specialOffer,
    comments: data.comments,
    availability: data.availability,
  };
};
