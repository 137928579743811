import { AGENT_ROLES_OPTIONS, LIMIT, REGEX_PATTERNS } from '@constants';
import { formatDate, getCleanedUrlParams } from '@utils';
import { pick } from 'lodash';

export const normalizeAgents = (data = []) => {
  if (!data.length > 0) {
    return [];
  }

  const transformedData = data?.map((item) => {
    return {
      text: item?.firstName
        ? `${item?.firstName} ${item?.lastName}`
        : item?.email,
      value: item?.email,
      role: AGENT_ROLES_OPTIONS[`is${item?.role}`]?.text || '',
    };
  });

  return transformedData?.sort((a, b) => a.text.localeCompare(b.text));
};

export const normalizeTeams = (data, agents) => {
  if (!data?.length > 0 || !agents?.length > 0) {
    return [];
  }

  const roles = Object.values(AGENT_ROLES_OPTIONS)
    ?.map((role) => role?.value)
    ?.join(', ');
  const agentMapping = (email) => {
    const agent = agents?.find((item) => item?.value === email);
    return agent?.text || '-';
  };

  const transformedData = data?.map((item) => {
    const individuals = item?.members?.filter((member) => {
      const regex = REGEX_PATTERNS.Email;
      return regex.test(member);
    });

    return {
      id: item?.id || '-',
      name: item?.name || '-',
      members: individuals.length,
      membersByRole: item?.members?.filter((member) => {
        return roles.includes(member) ? member : null;
      }),
      membersByName: item?.members?.filter((member) => {
        return roles.includes(member) ? null : member;
      }),
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      createdBy: item?.createdBy ? agentMapping(item?.createdBy) : '-',
      updatedAt: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY')
        : '-',
      updatedBy: item?.updatedBy ? agentMapping(item?.updatedBy) : '-',
    };
  });

  return transformedData;
};

export const getTeamsQueryParams = (payload = {}) => {
  const customFields = [];

  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  if (!payload.order) {
    payload.order = 'desc';
    payload.orderBy = 'createdAt';
  }

  Object.keys(payload)?.forEach((key) => {
    if (customFields.includes(key)) {
      payload[`customFields.${key}`] = payload[key];
      delete payload[key];
    }
  });

  return getCleanedUrlParams(
    pick(payload, ['page', 'pageSize', 'order', 'orderBy'])
  );
};

export const normalizeTeam = (data) => {
  if (!data) {
    return {};
  }

  const roles = Object.values(AGENT_ROLES_OPTIONS);

  data.createdAt = data?.createdAt
    ? formatDate(data?.createdAt, 'DD/MM/YYYY, HH:mm')
    : '';
  data.updatedAt = data?.updatedAt
    ? formatDate(data?.updatedAt, 'DD/MM/YYYY, HH:mm')
    : '';
  data.membersByRole = data?.members
    ?.filter((member) => roles.some((role) => role?.value === member))
    ?.map((member) => {
      const role = roles.find((r) => r?.value === member);
      return { text: role?.text, value: member };
    });
  data.membersByName = data?.members
    ?.filter((member) => {
      return !roles.find((role) => role?.value === member);
    })
    ?.map((member) => ({ text: member, value: member }));

  return data;
};
