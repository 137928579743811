import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { orderBy } from 'lodash';

import {
  agentsServices,
  boardsServices,
  fleetVehiclesServices,
  servicePointsServices,
} from '@services';
import { asyncForEach, showError } from '@utils';
import { SERVICE_POINTS_PAGE_SIZE } from '@constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import { ARNM_REPAIRS_TASK_STATUSES_NAMES } from 'components/arnmRepairs/constants';
import {
  getPayload,
  handleArnmRepairTaskData,
  normaliseAssigneeData,
  normalizeServicePointsTaskData,
  normalizeVehicleData,
} from './utils';

const stages = orderBy(
  Object.keys(ARNM_REPAIRS_TASK_STATUSES_NAMES).reduce((acc, cur) => {
    acc.push({
      text: ARNM_REPAIRS_TASK_STATUSES_NAMES[cur].label,
      value: Number(cur),
      order: ARNM_REPAIRS_TASK_STATUSES_NAMES[cur].order,
    });

    return acc;
  }, []),
  'order'
);

export const useArnmRepairsTaskOperations = () => {
  const router = useRouter();
  const arnmRepairsTaskId = router.query?.arnmRepairsTaskId;

  const servicePointsResponse = useQuery(
    QUERY_KEYS.GET_SERVICE_POINTS_KEY,
    () =>
      servicePointsServices.getServicePoints({
        page: 1,
        pageSize: SERVICE_POINTS_PAGE_SIZE,
      })
  );

  const assigneesResponse = useQuery(QUERY_KEYS.GET_BILLING_ASSIGNEES_KEY, () =>
    agentsServices.getAgents({
      page: 1,
      pageSize: 3000,
    })
  );

  const taskResponse = useQuery(
    [QUERY_KEYS.GET_BILLING_TASK_KEY, arnmRepairsTaskId],
    () => boardsServices.getTaskById(arnmRepairsTaskId),
    {
      enabled: assigneesResponse.isFetched,
    }
  );

  const vehicleResponse = useQuery(
    [QUERY_KEYS.GET_VEHICLE_BY_INTERNAL_ID_KEY, arnmRepairsTaskId],
    () =>
      fleetVehiclesServices.getVehicleByInternalId(
        taskResponse.data.data.customFields?.carId
      ),
    {
      enabled:
        taskResponse.isFetched &&
        !!taskResponse?.data?.data?.customFields?.carId,
    }
  );

  const assignees =
    normaliseAssigneeData(assigneesResponse?.data?.data || []) || [];

  const mentionedUsers = assignees?.map?.((user) => {
    return { id: user?.value, display: user?.text };
  });

  const vehicle = normalizeVehicleData(vehicleResponse?.data?.data || {});
  const loading =
    taskResponse?.isLoading ||
    assigneesResponse?.isLoading ||
    vehicleResponse?.isLoading;
  const servicePoints = normalizeServicePointsTaskData(
    servicePointsResponse?.data?.data || []
  );

  const updateTask = async ({ values, images, documents }) => {
    try {
      const imagesToupLoad = images?.filter((img) => img?.localFile);
      const imagesToPatch = images?.filter(
        (img) => img?.isPatched && !img?.localFile
      );
      const documentsToUpLoad = documents?.filter((doc) => doc?.localFile);
      const documentsToPatch = documents?.filter(
        (doc) => doc?.isPatched && !doc?.localFile
      );
      const payload = getPayload(values);
      await boardsServices.updateTask(arnmRepairsTaskId, payload);

      await asyncForEach(imagesToupLoad, async (img) => {
        await boardsServices.uploadTaskImage(
          arnmRepairsTaskId,
          img?.file,
          img?.tags
        );
      });
      await asyncForEach(imagesToPatch, async (img) => {
        await boardsServices.updateTaskImage(arnmRepairsTaskId, img?.id, {
          tags: img?.tags,
        });
      });

      await asyncForEach(documentsToUpLoad, async (doc) => {
        await boardsServices.uploadTaskDocument(
          arnmRepairsTaskId,
          doc?.file,
          '',
          doc?.tags
        );
      });

      await asyncForEach(documentsToPatch, async (doc) => {
        await boardsServices.updateTaskDocument(arnmRepairsTaskId, doc?.id, {
          tags: doc?.tags,
        });
      });
    } catch (error) {
      showError(error);
    }
  };

  return {
    refresh: taskResponse.refetch,
    assignees,
    mentionedUsers,
    loading,
    error: taskResponse?.error,
    isSuccess: taskResponse?.isSuccess,
    stages,
    task: handleArnmRepairTaskData({
      data: taskResponse?.data?.data || [],
      assignees: assignees || [],
      stages,
      servicePoints,
    }),
    vehicle: loading ? {} : vehicle,
    servicePoints,
    updateTask,
  };
};
