import { PRICING_TYPES_MAP, TENANT_NAMES } from '@constants';
import { formatDate, timeFromNow, formatAmount, fixedNumber } from '@utils';

const _getContractPeriod = (contractPeriod) => {
  if (contractPeriod === '1') {
    return `${contractPeriod} μήνας`;
  }
  return contractPeriod ? `${contractPeriod} μήνες` : '-';
};

export const handleBookings = (array) => {
  if (!array) {
    return [];
  }
  return array?.map((booking) => {
    return {
      id: booking?.id,
      created_at: booking?.created_at
        ? formatDate(booking?.created_at, 'DD/MM/YYYY')
        : '-',
      updated_at: booking?.updated_at
        ? formatDate(booking?.updated_at, 'DD/MM/YYYY HH:mm')
        : '-',
      timeAgo: timeFromNow(booking?.created_at),
      email: booking?.email,
      pipeDriveId: booking?.pipedrive?.id,
      pipeDriveUrl: booking?.pipedrive?.url,
      is_active: booking?.is_active || '',
      region: booking?.region || '-',
      contract: booking?.contract || '-',
      contractPeriod: _getContractPeriod(booking?.contract_period),
      phoneNumber: booking?.phone_number || '-',
      vehicle: booking?.vehicle?.title || '-',
      origin: booking?.origin || '-',
      skuId: booking?.vehicle?.id,
      tenant: TENANT_NAMES[booking?.tenant],
      fee: booking?.products?.[1]?.finalPrice?.number
        ? formatAmount(fixedNumber(booking?.products?.[1]?.finalPrice?.number))
        : '-',
      initialFee: booking?.products?.[1]?.price?.number
        ? formatAmount(fixedNumber(booking?.products?.[1]?.price?.number))
        : '-',
      userInternalId: booking?.userInternalId || '-',
      userId: booking?.user_id,
    };
  });
};

export const handleBooking = (booking) => {
  return {
    ...booking,
    userDetails: {
      fullName: {
        label: 'Full Name:',
        renderedValue: `${booking?.user?.first_name || ''} ${
          booking?.user?.last_name || ''
        }`,
      },
      email: {
        label: 'Email:',
        renderedValue: `${booking?.user?.email || '-'}`,
      },
      internalId: {
        label: 'User Id:',
        renderedValue: `${booking?.userInternalId || '-'}`,
        link: booking?.userId ? `/users/${booking?.userId}` : undefined,
      },
      telephone: {
        label: 'Telephone:',
        renderedValue: `${booking?.user?.phone_number || '-'}`,
      },
      createdAt: {
        label: 'Created At:',
        renderedValue: booking?.created_at
          ? formatDate(booking?.created_at, 'DD/MM/YYYY HH:mm')
          : '-',
      },
      updatedAt: {
        label: 'Updated At:',
        renderedValue: booking?.updated_at
          ? formatDate(booking?.updated_at, 'DD/MM/YYYY HH:mm')
          : '-',
      },
      vehicle: {
        label: 'Vehicle:',
        renderedValue: booking?.vehicle?.title,
        link: booking?.vehicle?.url,
      },
      pipeDriveId: {
        label: 'Pipedrive ID:',
        renderedValue: booking?.pipedrive?.id,
        link: booking?.pipedrive?.url || '-',
        target: '_blank',
      },
      region: {
        label: 'Region:',
        renderedValue: booking?.region || '-',
      },
    },
    contractDetails: {
      contract: {
        label: 'Account Type:',
        renderedValue: booking?.contract_details?.contract || '-',
      },
      contractType: {
        label: 'Contract Type:',
        renderedValue: PRICING_TYPES_MAP[booking?.contractType] || '-',
      },
      contractPeriod: {
        label: 'Contract Period:',
        renderedValue: _getContractPeriod(
          booking?.contract_details?.contract_period
        ),
      },
      total: {
        label: 'Total:',
        renderedValue: booking?.contract_details?.total || '-',
      },
    },
    prepaidMonthsDetails: {
      prepaidMonthsNumber: {
        label: 'Number of Prepaid Months:',
        renderedValue: booking?.prepaid_months?.length || '-',
      },
      prepaidMonths: {
        label: 'Extra Prepaid Months:',
        renderedValue:
          booking?.prepaid_months?.length > 0
            ? booking?.prepaid_months?.map((month) => `${month}ος`).join(', ')
            : '-',
      },
      prepaidMonthsCost: {
        label: 'Prepaid Months Cost:',
        renderedValue:
          booking?.prepaid_months?.length && booking?.final_values?.fee
            ? formatAmount(
                fixedNumber(
                  booking.prepaid_months.length * booking.final_values.fee
                )
              )
            : '-',
      },
      marketingCampaignId: {
        label: 'Marketing Campaign ID:',
        renderedValue: booking?.marketingCampaignId || '-',
      },
      guarantee: {
        label: 'Guarantee:',
        renderedValue: '-',
      },
    },
    products: booking?.products?.map((item) => {
      return {
        ...item,
        price: item?.finalPrice
          ? new Intl.NumberFormat('el-GR', {
              style: 'currency',
              currency: item?.finalPrice?.currency,
            }).format(item?.finalPrice?.number)
          : '-',
        quantity: item?.quantity || '-',
        vat: item?.vat ? `${item.vat * 100}%` : '-',
        name: item?.name,
        category: item?.category,
        discount: item?.discount ? `${item.discount * 100}%` : '-',
        months: item?.customFields?.months
          ? item?.customFields?.months?.join(', ')
          : '-',
        extraMonths: item?.customFields?.extraMonths
          ? item?.customFields?.extraMonths?.join(', ')
          : '-',
      };
    }),
  };
};
