import { FILTER_OPERATOR_ENUMS, FILTER_RULE_ENUMS } from '@constants';
import { omit } from 'lodash';

export const getGreenCardPaylod = (payload = {}) => {
  const fields =
    omit(payload, ['page', 'pageSize', 'order', 'orderBy', 'searchTerm']) || {};
  const numberFields = [];
  const multyFilterStringFields = [];
  const multyFilterNumberFields = [];

  const _getValue = ({ field }) => {
    if (multyFilterStringFields.includes(field)) {
      return payload[field]?.split(',');
    }
    if (multyFilterNumberFields.includes(field)) {
      return payload[field]?.split(',').map((item) => Number(item));
    }
    return numberFields.includes(field)
      ? Number(payload[field])
      : payload[field];
  };
  const _getType = ({ field }) => {
    if (
      multyFilterStringFields.includes(field) ||
      multyFilterNumberFields.includes(field)
    ) {
      return FILTER_RULE_ENUMS.IsAnyOfRuleType;
    }
    return FILTER_RULE_ENUMS.ComparableRuleType;
  };

  const value = [
    ...Object.keys(fields).map((field) => ({
      field,
      operator: FILTER_OPERATOR_ENUMS['='],
      type: _getType({ field }),
      value: _getValue({ field }),
    })),
  ];

  if (value.length) {
    return {
      type: FILTER_RULE_ENUMS.AndRuleType,
      field: null,
      operator: null,
      value,
    };
  }

  return {
    type: FILTER_RULE_ENUMS.EmptyRuleType,
  };
};

export const getGreenCardTaskPayload = (values) => {
  const clearableFields = [];

  Object.keys(values)?.forEach((key) => {
    if (values[key] === '' && !clearableFields.includes(key)) {
      values[key] = null;
    }
  });

  const cleanedValues = omit(values, [
    'assignee',
    'changelog',
    'comments',
    'createdAt',
    'createdBy',
    'customFields.carId',
    'customFields.comments',
    'customFields.delivered',
    'customFields.rejectedReason',
    'customFields.servicePoint',
    'customFields.subscriptionId',
    'customFields.userFullName',
    'customFields.userId',
    'customFields.vehicleBrand',
    'customFields.vehicleDescription',
    'customFields.vehicleEdition',
    'customFields.vehicleFuel',
    'customFields.vehicleImage',
    'customFields.vehicleModel',
    'customFields.vehicleOwner',
    'customFields.vehiclePlate',
    'customFields.vehicleTransmission',
    'customFields.vehicleYear',
    'customFields.vin',
    'documents',
    'images',
    'name',
    'parent',
    'status',
    'tags',
    'updatedAt',
    'updatedBy',
  ]);

  return {
    customFields: {
      ...cleanedValues.customFields,
      country: cleanedValues.customFields?.country?.map((c) => c?.value),
      mixedInsuranceExtensionPrice: cleanedValues.customFields
        ?.mixedInsuranceExtensionPrice?.number
        ? `${cleanedValues.customFields?.mixedInsuranceExtensionPrice?.number}`
        : null,
      customerCost: cleanedValues.customFields?.customerCost?.number
        ? `${cleanedValues.customFields?.customerCost?.number}`
        : null,
    },
  };
};
