/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectArnmMaintenanceState,
  getArnmMaintenance,
  selectArnmMaintenanceColumnsState,
} from '@store';
import { showError } from '@utils';
import { boardsServices } from '@services';
import { BOARDS, BOARDS_ID } from '@constants';

export const useArnmMaintenanceOperations = (fetchOnMount) => {
  const {
    total,
    loading,
    arnmMaintenance,
    pageSize,
    hasErrors,
    errorMessage,
    assignees,
  } = useSelector(selectArnmMaintenanceState);
  const {
    isReady,
    push,
    query: { page = 1, orderBy = 'createdAt', order = 'desc', token },
  } = useRouter();
  const dispatch = useDispatch();
  const columns = useSelector(selectArnmMaintenanceColumnsState);

  const _getArnmMaintenance = useCallback(async () => {
    let payload = localStorage.arnmMaintenanceQuery;
    let { arnmMaintenanceFilterRules } = localStorage;
    const defaultRule = {
      type: 5,
      field: 'boardId',
      operator: 0,
      value: BOARDS_ID.ARM_MAINTENANCE,
    };

    if (arnmMaintenanceFilterRules) {
      arnmMaintenanceFilterRules = JSON.parse(arnmMaintenanceFilterRules);
    } else {
      arnmMaintenanceFilterRules = [];
    }
    arnmMaintenanceFilterRules = [defaultRule, ...arnmMaintenanceFilterRules];
    if (payload) {
      payload = JSON.parse(payload);
      if (payload.value) {
        payload.value = payload.value.map((item) => {
          if (item.field === 'customFields.vehiclePlate') {
            return { ...item, value: item.value.toUpperCase() };
          }
          return item;
        });
        payload.value = [...payload.value, ...arnmMaintenanceFilterRules];
      } else if (arnmMaintenanceFilterRules?.length) {
        payload = {
          type: 0,
          field: null,
          operator: null,
          value: arnmMaintenanceFilterRules,
        };
      }
    } else if (!payload && arnmMaintenanceFilterRules?.length) {
      payload = {
        type: 0,
        field: null,
        operator: null,
        value: arnmMaintenanceFilterRules,
      };
    } else {
      payload = defaultRule;
    }
    await dispatch(
      getArnmMaintenance({
        orderBy,
        order,
        page,
        pageSize,
        boardId: BOARDS_ID.ARM_MAINTENANCE,
        boardType: BOARDS.ARM_MAINTENANCE,
        ...payload,
      })
    );
  }, [page, orderBy, order, dispatch]);

  const _addTask = useCallback(
    async (payload = {}) => {
      await boardsServices.addTask(
        payload?.parent
          ? payload?.parent?.split('-')[0]
          : arnmMaintenance?.boardId,
        { ...payload }
      );
      await _getArnmMaintenance();
    },
    [arnmMaintenance]
  );

  const _updateTask = useCallback(
    async (payload = {}) => {
      try {
        if (payload?.cardId) {
          const taskId = payload?.cardId;
          delete payload?.cardId;
          await boardsServices.updateTask(taskId, payload);
        } else {
          await boardsServices.updateTask(payload?.id, payload);
        }
      } catch (error) {
        showError(error);
      }
    },
    [arnmMaintenance]
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/arnmMaintenance?orderBy=${
        sortingModel?.orderBy || ''
      }&order=${sortingModel?.order || ''}&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/arnmMaintenance?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/arnmMaintenance?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/arnmMaintenance?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    setTimeout(() => {
      if (fetchOnMount && isReady) {
        _getArnmMaintenance();
      }
    }, 100);
  }, [page, token, _getArnmMaintenance, fetchOnMount, isReady]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    pageSize,
    loading,
    arnmMaintenance,
    columns,
    hasErrors,
    errorMessage,
    assignees,
    addTask: _addTask,
    updateTask: _updateTask,
    getArnmMaintenance: _getArnmMaintenance,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
