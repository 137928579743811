import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { asyncForEach, getCleanedUrlParams, getUrl, showError } from '@utils';
import { QUERY_KEYS } from 'constants/queryKeys';
import { URLS } from 'layouts/DrawerLayout/constants';
import { insurancesServices } from '@services';
import { LIMIT } from '@constants';
import { handleInsurancesData } from './utils';

export const useInsurancesOperations = (fetchOnMount) => {
  const router = useRouter();
  const {
    query: { page = '1', orderBy = 'internalId', order = 'asc' },
    isReady,
  } = router;

  const cleanParams = getCleanedUrlParams({
    page,
    orderBy,
    order,
    pageSize: LIMIT,
  });
  const insurancesResponse = useQuery(
    [QUERY_KEYS.GET_INSURANCES_KEY, cleanParams],

    () => insurancesServices.getInsurances(cleanParams),
    { enabled: fetchOnMount && isReady }
  );

  useEffect(() => {
    if (insurancesResponse?.error) {
      showError(insurancesResponse?.error);
    }
  }, [insurancesResponse?.error]);

  const handleDeleteInsurances = async (selectedInsurances) => {
    try {
      await asyncForEach(selectedInsurances, async (insuranceId) => {
        await insurancesServices.deleteInsurance(insuranceId);
      });

      insurancesResponse.refetch();
    } catch (error) {
      showError(error);
    }
  };

  const handlePageChange = (_event, newPage) => {
    router.push(getUrl({ ...router.query, page: newPage }, URLS.insurances));
  };

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...router.query, ...sortByModel }, URLS.insurances);

    router.push(url);
  };

  const insurances = handleInsurancesData(insurancesResponse?.data?.data || []);
  return {
    page,
    total: insurancesResponse?.data?.meta?.total,
    loading: insurancesResponse?.isLoading,
    insurances,
    pageSize: LIMIT,
    hasErrors: !!insurancesResponse?.error,
    errorMessage: insurancesResponse?.error,
    getInsurances: insurancesResponse?.refetch,
    handlePageChange,
    deleteInsurances: handleDeleteInsurances,
    handleSortBy,
  };
};
