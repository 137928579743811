import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { getCleanedUrlParams, getUrl, showError } from '@utils';
import { FILTER_RULE_ENUMS, LIMIT } from '@constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import { agentsServices, teamsServices } from '@services';
import { URLS } from 'layouts/DrawerLayout/constants';
import { getTeamsQueryParams, normalizeAgents, normalizeTeams } from './utils';

export const useTeamsOperations = (enableFetch = true) => {
  const router = useRouter();
  const pageSize = LIMIT;

  const page = router.query?.page || 1;
  const orderBy = router.query?.orderBy;
  const order = router.query?.order || 'asc';

  const params = {
    orderBy,
    order,
    page,
  };

  const cleanParams = getTeamsQueryParams(params);

  const agentsResponse = useQuery(
    [QUERY_KEYS.GET_AGENTS_KEY],
    () => agentsServices.getAgents({ pageSize: 1000 }),
    { enabled: enableFetch, refetchOnMount: false }
  );

  const teamsResponse = useQuery(
    [QUERY_KEYS.GET_TEAMS_KEY, cleanParams],
    () =>
      teamsServices.getTeams(getCleanedUrlParams(params), {
        type: FILTER_RULE_ENUMS.EmptyRuleType,
      }),
    { enabled: agentsResponse.isFetched, refetchOnMount: true }
  );

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...router.query, ...sortByModel }, URLS.teams);
    router.push(url);
  };

  const handlePageChange = (_event, newPage) => {
    router.push(getUrl({ ...router.query, page: newPage }), URLS.teams);
  };

  const agents = normalizeAgents(agentsResponse?.data?.data || []);
  const teams = normalizeTeams(teamsResponse?.data?.data || [], agents);

  useEffect(() => {
    if (teamsResponse.isError) {
      showError(teamsResponse?.error);
    }
  }, [teamsResponse.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    page,
    pageSize,
    total: teamsResponse?.data?.meta?.total,
    loading: teamsResponse?.isLoading,
    teams,
    agents,
    hasErrors: teamsResponse?.isError,
    errorMessage: teamsResponse?.error,
    getTeams: teamsResponse.refetch,
    nextPage: handlePageChange,
    sortBy: handleSortBy,
  };
};
