import dynamic from 'next/dynamic';

const InsurancesGrid = dynamic(() => import('./InsurancesGrid/InsurancesGrid'));
const AddInsurance = dynamic(() => import('./AddInsurance/AddInsurance'));
const InsuranceDetails = dynamic(() =>
  import('./InsuranceDetails/InsuranceDetails')
);
const EditInsuranceFieldsModal = dynamic(() =>
  import('./EditInsuranceFieldsModal/EditInsuranceFieldsModal')
);

export {
  InsurancesGrid,
  AddInsurance,
  InsuranceDetails,
  EditInsuranceFieldsModal,
};
