import dynamic from 'next/dynamic';

const VehiclesGrid = dynamic(() => import('./VehiclesGrid/VehiclesGrid'));
const VehicleFilters = dynamic(() => import('./VehicleFilters/VehicleFilters'));
const VehicleHeader = dynamic(() => import('./VehicleHeader/VehicleHeader'));
const VehicleOverview = dynamic(() =>
  import('./VehicleOverview/VehicleOverview')
);
const VehicleSku = dynamic(() => import('./VehicleSku/VehicleSku'));
const CarsForm = dynamic(() => import('./CarsForm/CarsForm'));
const FormGrid = dynamic(() => import('./FormGrid/FormGrid'));
const VehiclePropertiesSection = dynamic(() =>
  import('./VehiclePropertiesSection/VehiclePropertiesSection')
);
const VehiclePricingSection = dynamic(() =>
  import('./VehiclePricingSection/VehiclePricingSection')
);
const VehiclePricingGrid = dynamic(() =>
  import('./VehiclePricingGrid/VehiclePricingGrid')
);
const CatalogPageContent = dynamic(() =>
  import('./CatalogPageContent/CatalogPageContent')
);

export {
  VehiclesGrid,
  VehicleFilters,
  VehicleHeader,
  VehicleOverview,
  VehicleSku,
  CarsForm,
  FormGrid,
  VehiclePropertiesSection,
  VehiclePricingSection,
  VehiclePricingGrid,
  CatalogPageContent,
};
