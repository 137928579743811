import dynamic from 'next/dynamic';

const BookingsGrid = dynamic(() => import('./BookingsGrid/BookingsGrid'));

const BookingsFilter = dynamic(() => import('./BookingsFilter/BookingsFilter'));
const BookingsHeaderOptions = dynamic(() =>
  import('./BookingsHeaderOptions/BookingsHeaderOptions')
);
const NewBookingModal = dynamic(() =>
  import('./NewBookingModal/NewBookingModal')
);
const NewBookingForm = dynamic(() => import('./NewBookingForm/NewBookingForm'));
const BookingProductsGrid = dynamic(() =>
  import('./BookingProductsGrid/BookingProductsGrid')
);

export {
  BookingsGrid,
  BookingsFilter,
  BookingsHeaderOptions,
  NewBookingModal,
  NewBookingForm,
  BookingProductsGrid,
};
