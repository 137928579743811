import { pick, omit, pickBy } from 'lodash';
import { FILTER_RULE_ENUMS, FILTER_OPERATOR_ENUMS } from '@constants';
import { BUYOUT_LIMIT } from '@store';

export const getBuyOutQueryParams = (payload = {}) => {
  if (!payload?.pageSize) {
    payload.pageSize = BUYOUT_LIMIT;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  return pick(payload, ['page', 'pageSize', 'order', 'orderBy']);
};

export const getBuyOutRequestPayload = (
  payload = {},
  modalFiltersRules = null
) => {
  const params =
    omit(payload, ['page', 'pageSize', 'order', 'orderBy', 'searchTerm']) || {};
  const fields = pickBy(
    params,
    (item) => item !== null && item !== undefined && item !== ''
  );
  const numberFields = [];

  const multyFilterStringFields = [];
  const multyFilterNumberFields = ['status'];

  const { searchTerm } = payload;

  const _getValue = ({ field }) => {
    if (multyFilterStringFields.includes(field)) {
      return payload[field]?.split(',');
    }
    if (multyFilterNumberFields.includes(field)) {
      return payload[field]?.split(',').map((item) => Number(item));
    }
    return numberFields.includes(field)
      ? Number(payload[field])
      : payload[field];
  };
  const _getType = ({ field }) => {
    if (
      multyFilterStringFields.includes(field) ||
      multyFilterNumberFields.includes(field)
    ) {
      return FILTER_RULE_ENUMS.IsAnyOfRuleType;
    }
    return FILTER_RULE_ENUMS.ComparableRuleType;
  };

  const value = [
    ...Object.keys(fields).map((field) => ({
      field,
      operator: FILTER_OPERATOR_ENUMS['='],
      type: _getType({ field }),
      value: _getValue({ field }),
    })),
  ];

  value.push(...(modalFiltersRules?.value || []));

  if (searchTerm) {
    const filedsToSearch = [
      'id',
      'customFields.vehiclePlate',
      'customFields.subscriberEmail',
      'customFields.loanContractId',
    ];

    const searchRule = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      value: filedsToSearch.map((field) => ({
        type: FILTER_RULE_ENUMS.CompareStringRuleType,
        operator: FILTER_OPERATOR_ENUMS.contains,
        field,
        value: searchTerm,
      })),
    };
    value.push(searchRule);
  }

  if (value.length) {
    return {
      type: FILTER_RULE_ENUMS.AndRuleType,
      field: null,
      operator: null,
      value,
    };
  }

  return {
    type: FILTER_RULE_ENUMS.EmptyRuleType,
  };
};
