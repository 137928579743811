import theme from 'config/theme';

export const SELL_REQUESTS_PAGE_SIZE = 1000;

export const SELL_REQUESTS_BOARD = {
  label: 'sellRequests',
  key: 'sellRequests',
  type: 10,
  id: 'VSL',
  statuses: {
    55: {
      label: 'Pre Leads',
      styles: {
        backgroundColor: theme.palette.grays.gray300,
        color: theme.palette.grays.gray900,
      },
    },
    56: {
      label: 'Leads',
      styles: {
        backgroundColor: theme.palette.mints.mint200,
        color: theme.palette.mints.mint900,
      },
    },
    57: {
      label: 'Tel Contact 1',
      styles: {
        backgroundColor: theme.palette.grays.gray200,
        color: theme.palette.grays.gray600,
      },
    },
    58: {
      label: 'No Answer',
      styles: {
        backgroundColor: theme.palette.violets.violet200,
        color: theme.palette.violets.violet900,
      },
    },
    59: {
      label: 'Tel Contact 2',
      styles: {
        backgroundColor: theme.palette.grays.gray400,
        color: theme.palette.grays.gray900,
      },
    },
    60: {
      label: 'Follow Up',
      styles: {
        backgroundColor: theme.palette.purples.purple200,
        color: theme.palette.purples.purple900,
      },
    },
    61: {
      label: 'Finance Approval',
      styles: {
        backgroundColor: theme.palette.limes.lime200,
        color: theme.palette.limes.lime900,
      },
    },
    62: {
      label: 'Book Appointment',
      styles: {
        backgroundColor: theme.palette.pinks.pink200,
        color: theme.palette.pinks.pink900,
      },
    },
    7: {
      label: 'Loans',
      styles: {
        backgroundColor: theme.palette.blues.babyBlue200,
        color: theme.palette.blues.babyBlue900,
      },
    },
    8: {
      label: 'Buyout Deposit',
      styles: {
        backgroundColor: theme.palette.blues.blue100,
        color: theme.palette.blues.blue900,
      },
    },
    9: {
      label: 'Vehicle Sales',
      styles: {
        backgroundColor: theme.palette.yellows.yellow200,
        color: theme.palette.yellows.yellow900,
      },
    },
    10: {
      label: 'Transferred',
      styles: {
        backgroundColor: theme.palette.blues.primaryBlue300,
        color: theme.palette.blues.blue900,
      },
    },
    63: {
      label: 'Partner Vehicles',
      styles: {
        backgroundColor: theme.palette.greens.green400,
        color: theme.palette.greens.green900,
      },
    },
    29: {
      label: 'For Delivery',
      styles: {
        backgroundColor: theme.palette.teals.teal200,
        color: theme.palette.teals.teal900,
      },
    },
    3: {
      label: 'Done',
      styles: {
        backgroundColor: theme.palette.greens.green200,
        color: theme.palette.greens.green900,
      },
    },
    11: {
      label: 'Customer Rejected',
      styles: {
        backgroundColor: theme.palette.reds.red200,
        color: theme.palette.reds.red900,
      },
    },
    12: {
      label: 'Cancelled',
      styles: {
        backgroundColor: theme.palette.oranges.orange200,
        color: theme.palette.oranges.orange900,
      },
    },
  },
  statusOrder: [55, 56, 57, 58, 59, 60, 61, 62, 7, 8, 9, 10, 63, 29, 3, 11, 12],
};
