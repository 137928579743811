import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { subscriptionsServices } from '@services';
import { GENERIC_ERROR_MESSAGE, FILTER_RULE_ENUMS } from '@constants';
import {
  getSubscriptionsQueryParams,
  getSubscriptionsRequestPayload,
} from 'helpers';
import {
  handleSubscription,
  handleSubscriptions,
} from './subscriptions.handlers';

export const SUBSCRIPTIONS_LIMIT = 200;
export const SUBSCRIPTIONS_EXPORT_LIMIT = 20000;

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  subscriptions: [],
  subscription: {},
  getSubscriptionHasErrors: false,
  getSubscriptionErrorMessage: '',
  filters: {
    urlParams: undefined,
    modalFiltersRules: undefined,
    modalConditions: undefined,
  },
  total: 0,
  pageSize: SUBSCRIPTIONS_LIMIT,
};
// Actual Slice
export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    fetchSubscriptions: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.subscriptions = [];
    },
    fetchSubscriptionsSuccess: (state, { payload }) => {
      state.subscriptions = handleSubscriptions(payload?.response?.data);
      state.total = payload?.response?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.filters.modalFiltersRules = payload.modalFiltersRules;
      state.filters.urlParams = payload.urlParams;
    },
    fetchSubscriptionsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchSubscription: (state) => {
      state.loading = true;
      state.getSubscriptionHasErrors = false;
      state.getSubscriptionErrorMessage = '';
    },
    fetchSubscriptionSuccess: (state, { payload }) => {
      state.subscription = handleSubscription(payload);
      state.loading = false;
    },
    fetchSubscriptionFailure: (state, { payload }) => {
      state.loading = false;
      state.getSubscriptionHasErrors = true;
      state.getSubscriptionErrorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchSubscriptionHistory: (state) => {
      state.subscription.history = {
        hasErrors: false,
        errorMessage: '',
        loading: true,
      };
    },
    fetchSubscriptionHistorySuccess: (state, { payload }) => {
      state.subscription.history.changelog = payload?.data;
      state.subscription.history.loading = false;
    },
    fetchSubscriptionHistoryFailure: (state, { payload }) => {
      state.subscription.history.loading = false;
      state.subscription.history.hasErrors = true;
      state.subscription.history.errorMessage =
        payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchSubscriptions,
  fetchSubscriptionsSuccess,
  fetchSubscriptionsFailure,
  fetchSubscription,
  fetchSubscriptionSuccess,
  fetchSubscriptionFailure,
  fetchSubscriptionHistory,
  fetchSubscriptionHistorySuccess,
  fetchSubscriptionHistoryFailure,
} = subscriptionsSlice.actions;

export const selectSubscriptionsState = (state) => state.subscriptions;
export const selectSubscriptionHistoryState = (state) =>
  state.subscriptions.subscription.history;

export default subscriptionsSlice.reducer;

export const getSubscriptions = ({
  urlParams = {},
  modalFiltersRules = null,
}) => {
  return async (dispatch) => {
    dispatch(fetchSubscriptions());
    try {
      const params = getSubscriptionsQueryParams(urlParams);
      const payload = getSubscriptionsRequestPayload(
        urlParams,
        modalFiltersRules
      );
      const response = await subscriptionsServices?.getSubscriptions({
        params,
        payload,
      });
      response.meta.page = urlParams.page || 1;

      dispatch(
        fetchSubscriptionsSuccess({ response, urlParams, modalFiltersRules })
      );
    } catch (error) {
      dispatch(fetchSubscriptionsFailure(error));
    }
  };
};

export const getSubscription = (id, fetchLinkedSubscriptions = false) => {
  return async (dispatch) => {
    dispatch(fetchSubscription());
    try {
      let linkedSubscriptionsResponse = {};
      const response = await subscriptionsServices?.getSubscriptionById(id);
      if (fetchLinkedSubscriptions) {
        if (response.data.standardSubscriptionId) {
          // is child
          const linkedSubscriptionResponse =
            await subscriptionsServices?.getSubscriptionById(
              response.data.standardSubscriptionId
            );
          linkedSubscriptionsResponse.data = [linkedSubscriptionResponse.data];
        } else {
          // is parent
          linkedSubscriptionsResponse =
            await subscriptionsServices.getSubscriptions({
              payload: {
                type: FILTER_RULE_ENUMS.ComparableRuleType,
                field: 'standardSubscriptionId',
                operator: 0,
                page: 1,
                value: id,
              },
              params: {
                page: 1,
                pageSize: 500,
              },
            });
        }
      }
      dispatch(
        fetchSubscriptionSuccess({
          subscription: response.data,
          linkedSubscriptions: linkedSubscriptionsResponse.data || [],
        })
      );
    } catch (error) {
      dispatch(fetchSubscriptionFailure(error));
    }
  };
};

export const getSubscriptionHistory = (id) => {
  return async (dispatch) => {
    dispatch(fetchSubscriptionHistory());
    try {
      const response = await subscriptionsServices?.getSubscriptionHistoryById(
        id
      );

      dispatch(fetchSubscriptionHistorySuccess(response));
    } catch (error) {
      dispatch(fetchSubscriptionHistoryFailure(error));
    }
  };
};
