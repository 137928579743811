import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectEbikesState, getEbikes } from '@store';
import { showError } from '@utils';
import { getCatalogEbikesRequestPayload } from 'helpers/catalog/ebikes';
import { URLS } from 'layouts/DrawerLayout/constants';

export const useEbikesOperations = (fetchOnMount) => {
  const { total, loading, ebikes, pageSize, hasErrors, errorMessage } =
    useSelector(selectEbikesState);
  const {
    push,
    query: { page = 1, orderBy = '', order = '', token, ...others },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _otherQueries = Object.entries(others)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const _getEbikes = useCallback(async () => {
    const modalFiltersRules =
      localStorage.marketEbikesQuery &&
      JSON.parse(localStorage.marketEbikesQuery);
    const payload = getCatalogEbikesRequestPayload(others, modalFiltersRules);

    await dispatch(getEbikes({ orderBy, order, page, ...payload }));
  }, [page, orderBy, order, _otherQueries, dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `${URLS.catalogEbikes}?page=${page}&${_otherQueries}`;
      if (sortingModel?.orderBy || sortingModel?.order) {
        url = `${url}&orderBy=${sortingModel?.orderBy || ''}&order=${
          sortingModel?.order || ''
        }`;
      }
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `${URLS.catalogEbikes}?page=${page}&${_otherQueries}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page, _otherQueries]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `${URLS.catalogEbikes}?&page=${currentPage}&${_otherQueries}`;

      if (orderBy || order) {
        url = `${url}&orderBy=${orderBy || ''}&order=${order || ''}`;
      }
      if (!Object.keys(others).length && (!orderBy || !order)) {
        url = `${URLS.catalogEbikes}?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push, _otherQueries, others]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getEbikes();
    }
  }, [_getEbikes, token, fetchOnMount, isReady, _otherQueries]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    loading,
    ebikes,
    pageSize,
    hasErrors,
    errorMessage,
    getEbikes: _getEbikes,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
