/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGreenCardState,
  getGreenCard,
  selectGreenCardsColumnsState,
} from '@store';
import { showError } from '@utils';
import { boardsServices } from '@services';
import { BOARDS, BOARDS_ID } from '@constants';

export const useGreenCardOperations = (fetchOnMount) => {
  const { total, loading, greenCard, pageSize, hasErrors, errorMessage } =
    useSelector(selectGreenCardState);
  const columns = useSelector(selectGreenCardsColumnsState);

  const {
    isReady,
    push,
    query: { page = 1, orderBy = 'createdAt', order = 'desc', token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getGreenCard = useCallback(async () => {
    let payload = localStorage.greenCardQuery;
    let { greenCardFilterRules } = localStorage;
    const defaultRule = {
      type: 5,
      field: 'boardId',
      operator: 0,
      value: BOARDS_ID.GREEN_CARD,
    };

    if (greenCardFilterRules) {
      greenCardFilterRules = JSON.parse(greenCardFilterRules);
    } else {
      greenCardFilterRules = [];
    }
    greenCardFilterRules = [defaultRule, ...greenCardFilterRules];
    if (payload) {
      payload = JSON.parse(payload);
      if (payload.value) {
        payload.value = [...payload.value, ...greenCardFilterRules];
      } else if (greenCardFilterRules?.length) {
        payload = {
          type: 0,
          field: null,
          operator: null,
          value: greenCardFilterRules,
        };
      }
    } else if (!payload && greenCardFilterRules?.length) {
      payload = {
        type: 0,
        field: null,
        operator: null,
        value: greenCardFilterRules,
      };
    } else {
      payload = defaultRule;
    }
    await dispatch(
      getGreenCard({
        orderBy,
        order,
        page,
        pageSize,
        boardId: BOARDS_ID.GREEN_CARD,
        boardType: BOARDS.GREEN_CARD,
        ...payload,
      })
    );
  }, [page, orderBy, order, dispatch]);

  const _addTask = useCallback(
    async (payload = {}) => {
      await boardsServices.addTask(greenCard?.boardId || BOARDS_ID.GREEN_CARD, {
        ...payload,
      });
      await _getGreenCard();
    },
    [greenCard]
  );

  const _updateTask = useCallback(
    async (payload = {}) => {
      try {
        if (payload?.cardId) {
          const taskId = payload?.cardId;
          delete payload?.cardId;
          await boardsServices.updateTask(taskId, payload);
        } else {
          await boardsServices.updateTask(payload?.id, payload);
        }
      } catch (error) {
        showError(error);
      }
    },
    [greenCard]
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/greenCard?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/greenCard?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/greenCard?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/greenCard?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    setTimeout(() => {
      if (fetchOnMount && isReady) {
        _getGreenCard();
      }
    }, 100);
  }, [page, token, _getGreenCard, fetchOnMount, isReady]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    pageSize,
    loading,
    greenCard,
    columns,
    hasErrors,
    errorMessage,
    addTask: _addTask,
    updateTask: _updateTask,
    getGreenCard: _getGreenCard,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
