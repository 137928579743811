/* eslint-disable */
import { groupBy, isEmpty, omit, pick } from 'lodash';

import { formatDate, getCleanedUrlParams, sortArray } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';
import theme from 'config/theme';

import { FILTER_OPERATOR_ENUMS, FILTER_RULE_ENUMS } from '@constants';
import {
  DEFLEET_PAGE_SIZE,
  DEFLEET_TABS,
  DEFLEET_OUTCOMES_API,
} from './constants';

export const getColumns = (tasks = [], board = '') => {
  const order = DEFLEET_TABS[board].statusOrder;
  const groupedByStatus = groupBy(tasks, 'status');

  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: DEFLEET_TABS[board]?.statuses?.[cur]?.label || cur,
      cards: groupedByStatus[cur] || [],
      styles: {
        backgroundColor: theme.palette.blues.babyBlue200,
        color: theme.palette.blues.babyBlue900,
      },
      value: true,
      label: DEFLEET_TABS[board]?.statuses?.[cur]?.label || cur,
      key: cur,
    });

    return acc;
  }, []);

  return columns;
};

export const normaliseDefleetTasks = (data = [], assignees = []) => {
  if (!data.length > 0) {
    return {};
  }
  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.email] = {
      ...cur,
      shortName: `${cur.firstName?.[0]}${cur.lastName?.[0]}`.toUpperCase(),
    };
    return acc;
  }, {});

  const tasks = data.map((item) => {
    const assignee = assigneesMap[item.assignee] || {};
    return {
      ...item,
      href: `${URLS.defleet}/${item?.id}`,
      plate: item.customFields?.plate || '',
      status: item.status,
      assignee,
      date: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : null,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      id: item?.id,
      carId: item.customFields?.carId || '-',
      subscriptionId: item.customFields?.subscriptionId || '-',
      brand: item.customFields?.brand || '-',
      model: item.customFields?.model || '-',
      edition: item.customFields?.edition || '-',
      year: item.customFields?.year || '-',
      target: item.customFields?.target || '-',
      promisedReturnDate: item.customFields?.promisedReturnDate
        ? formatDate(item.customFields?.promisedReturnDate, 'DD/MM/YYYY')
        : '-',
      returnDate: item.customFields?.returnDate
        ? formatDate(item.customFields?.returnDate, 'DD/MM/YYYY')
        : '-',
      monthlyFee: item.customFields?.monthlyFee
        ? item.customFields?.monthlyFee?.number
        : '-',
      outcome: item.customFields?.outcome
        ? DEFLEET_OUTCOMES_API?.[item.customFields?.outcome]
        : '-',
      description: `${item.customFields?.brand} ${item.customFields?.model} ${item.customFields?.edition}`,
    };
  });

  return tasks;
};

export const normaliseAssigneeData = (data = []) =>
  data
    .map((item) => ({
      value: item.email,
      isActive: item?.isActive,
      text:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
      shortName:
        item.firstName && item.lastName
          ? `${item?.firstName[0].toUpperCase()}${item?.lastName[0].toUpperCase()}`
          : item.email[0].toUpperCase(),
    }))
    ?.sort((a, b) => a.text.localeCompare(b.text));

export const getDefleetQueryParams = (payload = {}) => {
  const customFields = [
    'brand',
    'model',
    'year',
    'target',
    'subscriptionStatus',
    'promisedReturnDate',
    'returnDate',
    'monthlyFee',
  ];
  if (!payload?.pageSize) {
    payload.pageSize = DEFLEET_PAGE_SIZE;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  if (!payload.order) {
    payload.order = 'desc';
    payload.orderBy = 'createdAt';
  }

  if (payload.monthlyFee) {
    payload['monthlyFee'] = {
      value: payload.monthlyFee,
      type: FILTER_RULE_ENUMS.ComparePriceRuleType,
    };
  }

  Object.keys(payload)?.forEach((key) => {
    if (customFields.includes(key)) {
      payload[`customFields.${key}`] = payload[key];
      delete payload[key];
    }
  });

  return getCleanedUrlParams(
    pick(payload, [
      'page',
      'pageSize',
      'order',
      'orderBy',
      'boardType',
      'assignee',
      'searchTerm',
      'status',
      'customFields.brand',
      'customFields.model',
      'customFields.year',
      'customFields.target',
      'customFields.subscriptionStatus',
      'customFields.promisedReturnDate',
      'customFields.returnDate',
      'customFields.monthlyFee',
    ])
  );
};

export const getDefleetRequestPayload = (payload = {}) => {
  const fields =
    omit(payload, [
      'page',
      'pageSize',
      'order',
      'orderBy',
      'boardType',
      'searchTerm',
    ]) || {};
  const { searchTerm } = payload;

  const value = [
    // to be enabled in the future
    // {
    //   field: 'archived_at',
    //   operator: 0,
    //   type: FILTER_RULE_ENUMS.isEmpty,
    // },
    ...Object.keys(fields).map((field) => ({
      field,
      operator: FILTER_OPERATOR_ENUMS['='],
      type: payload[field]?.type || FILTER_RULE_ENUMS.ComparableRuleType,
      value: payload[field]?.value || payload[field],
    })),
  ];

  if (searchTerm) {
    const filedsToSearch = ['subscriptionId', 'carId', 'plate'];

    const searchRule = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      value: filedsToSearch.map((field) => ({
        type: FILTER_RULE_ENUMS.CompareStringRuleType,
        operator: FILTER_OPERATOR_ENUMS.contains,
        field: `customFields.${field}`,
        value: searchTerm,
      })),
    };
    value.push(searchRule);
  }

  return {
    boardType: payload.boardType,
    type: FILTER_RULE_ENUMS.AndRuleType,
    field: null,
    operator: null,
    value,
  };
};

const getValue = (data, current) => {
  return (
    data.find((item) => item?.value === current) || {
      value: '',
      text: '',
    }
  );
};

export const handleDefleetTaskData = ({
  data = {},
  assignees = [],
  stages = [],
}) => {
  if (!data || isEmpty(data)) {
    return {};
  }
  const assignee = getValue(assignees, data.assignee);
  const status = getValue(stages, data.status);

  const task = {
    ...data,
    assignee,
    status,
    changelog: data?.changelog?.reverse(),
    createdAt: data?.createdAt
      ? formatDate(data?.createdAt, 'DD-MM-YYYY')
      : '-',
    customFields: {
      ...omit(data.customFields, ['comments']),
    },
    subscriptionOwner: data.customFields.userFirstName
      ? `${data.customFields.userFirstName} ${data.customFields.userLastName}`
      : '',
    comments: sortArray(data?.comments, 'createdAt', 'desc')?.map((entry) => {
      return {
        id: entry?.id,
        date: entry?.createdAt
          ? formatDate(entry?.createdAt, 'DD MMMM YYYY')
          : '-',
        time: entry?.createdAt ? formatDate(entry?.createdAt, 'HH:mm') : '-',
        name: entry?.userFullName || entry?.user || '-',
        comment: entry?.comment,
        editDate: entry?.editedAt
          ? formatDate(entry?.editedAt, 'DD MMMM YYYY')
          : '',
        editTime: entry?.editedAt ? formatDate(entry?.editedAt, 'HH:mm') : '',
      };
    }),
  };
  return task;
};

export const normalizeVehicleData = (data = {}) => {
  const properties = [];

  if (data.properties?.fuelType) {
    properties.push(data.properties.fuelType);
  }
  if (data.properties?.transmission) {
    properties.push(data.properties.transmission);
  }
  if (data.properties?.color) {
    properties.push(data.properties.color);
  }
  if (data.year) {
    properties.push(data.year);
  }
  return {
    ...data,
    title: `${
      data.manufacturer
        ? `${data.manufacturer} ${data.model}, ${data.edition}`
        : '-'
    }`,
    image: data?.images?.[0]?.url,
    properties,
  };
};

export const normalizeUserData = (data = {}) => {
  const billingInfo = data?.billingInfo?.[0] || {};
  const lang = data?.lang ? (data?.lang === 'el' ? 'Greek' : 'English') : '-';
  return {
    id: data?.billingInfo?.length ? data.id : '',
    email: billingInfo?.email,
    firstName: billingInfo?.firstName,
    lastName: billingInfo?.lastName,
    fullName: billingInfo?.firstName
      ? `${billingInfo?.firstName} ${billingInfo?.lastName}`
      : '-',
    address: billingInfo?.address || '-',
    phoneNumber: billingInfo?.phoneNumber || '-',
    tin: billingInfo?.vat || '-',
    taxOffice: billingInfo?.doi || '-',
    city: billingInfo?.city || '-',
    language: lang,
  };
};

export const getPayload = (values) => {
  Object.keys(values)?.forEach((key) => {
    if (values[key] === '') {
      values[key] = null;
    }
  });

  const cleanedValues = omit(values, [
    'assignee',
    'documents',
    'tags',
    'status',
    'updatedAt',
    'updatedBy',
    'changelog',
    'images',
    'parent',
    'name',
    'createdAt',
    'comments',
    'createdBy',
    'customFields.promisedReturnDate',
    'customFields.returnDate',
    'customFields.returnPoint',
  ]);

  return {
    customFields: {
      ...cleanedValues.customFields,
    },
  };
};
