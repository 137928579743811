import { BOARDS_ID } from '@constants';
import { formatDate } from '@utils';
import {
  TRAFFIC_OFFENSES_REASONS,
  TRAFFIC_OFFENSES_STATUSES,
} from 'components/trafficOffenses/constants';

const getAssignee = (assignees, assignee) => {
  const selected = assignees.find((item) => item.email === assignee) || {
    value: '',
    text: '',
  };

  return {
    value: selected.email,
    text:
      selected.firstName && selected.lastName
        ? `${selected?.firstName} ${selected?.lastName}`
        : selected.email,
    shortName: `${selected.firstName?.[0] || ''}${
      selected.lastName?.[0] || ''
    }`.toUpperCase(),
  };
};
export const handleTrafficOffensesData = (data = [], assignees = []) => {
  if (!data.length) {
    return {};
  }

  const tasks = data.map((item) => {
    const assignee = getAssignee(assignees, item.assignee);

    return {
      ...item,
      id: item?.id,
      assignee,
      assigneeColumn: assignee?.text || '-',
      plate: item.customFields?.vehiclePlate || '',
      description: `${item.name}`,
      href: `/trafficOffenses/${item.id}`,
      userId: item.customFields.userId,
      userFullName: `${item.customFields.userFirstName || ''} ${
        item.customFields.userLastName || ''
      }`,
      carDescr: `${item.customFields.vehicleDescription} - ${item.customFields.vehiclePlate}`,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      date: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : null,
      status: item.status,
      columnStatus: TRAFFIC_OFFENSES_STATUSES[item.customFields.status] || '-',
      reason: TRAFFIC_OFFENSES_REASONS[item.customFields?.reason] || '-',
      dateOfViolation: item.customFields?.dateOfViolation
        ? formatDate(item.customFields?.dateOfViolation, 'DD/MM/YYYY')
        : '-',
      violationNumber: item.customFields.violationNumber || '-',
    };
  });

  return {
    boardId: BOARDS_ID.TRAFFIC_OFFENSES,
    tasks,
  };
};

export const handleAssigneeData = (data = []) =>
  data.map((item) => ({
    value: item.email,
    text:
      item.firstName && item.lastName
        ? `${item?.firstName} ${item?.lastName}`
        : item.email,
  }));
