/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectReturnsInboxState, getReturnsInbox } from '@store';
import { showError } from '@utils';
import { boardsServices } from '@services';

export const useReturnsInboxOperations = (fetchOnMount) => {
  const { total, loading, returnsInbox, pageCount, hasErrors, errorMessage } =
    useSelector(selectReturnsInboxState);
  const {
    isReady,
    push,
    query: { page = 1, orderBy = '', order = '', token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getReturnsInbox = useCallback(
    async (payload = {}) => {
      await dispatch(getReturnsInbox({ ...payload, orderBy, order, page }));
    },
    [page, orderBy, order, dispatch]
  );

  const _addTask = useCallback(
    async (payload = {}) => {
      await boardsServices.addTask(
        payload?.parent
          ? payload?.parent?.split('-')[0]
          : returnsInbox?.boardId,
        { ...payload }
      );
      await _getReturnsInbox();
    },
    [returnsInbox]
  );

  const _updateTask = useCallback(
    async (payload = {}) => {
      try {
        if (payload?.cardId) {
          const taskId = payload?.cardId;
          delete payload?.cardId;
          await boardsServices.updateTask(taskId, payload);
        } else {
          await boardsServices.updateTask(payload?.id, payload);
        }
      } catch (error) {
        showError(error);
      }
    },
    [returnsInbox]
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/returnsInbox?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/returnsInbox?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/returnsInbox?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/returnsInbox?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    setTimeout(() => {
      if (fetchOnMount && isReady) {
        _getReturnsInbox();
      }
    }, 100);
  }, [page, token, _getReturnsInbox, fetchOnMount, isReady]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    loading,
    returnsInbox,
    pageCount,
    hasErrors,
    errorMessage,
    addTask: _addTask,
    updateTask: _updateTask,
    getReturnsInbox: _getReturnsInbox,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
