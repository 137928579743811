import dynamic from 'next/dynamic';

const ProductsGrid = dynamic(() => import('./ProductsGrid/ProductsGrid'));
const ProductsForm = dynamic(() => import('./ProductsForm/ProductsForm'));
const SignUpFeeFields = dynamic(() =>
  import('./SignUpFeeFields/SignUpFeeFields')
);
const FormTitle = dynamic(() => import('./FormTitle/FormTitle'));
const ProductOverview = dynamic(() =>
  import('./ProductOverview/ProductOverview')
);
const PricePerKilometersFields = dynamic(() =>
  import('./PricePerKilometersFields/PricePerKilometersFields')
);
const InstastartFields = dynamic(() =>
  import('./InstastartFields/InstastartFields')
);
const InstadeliveryFields = dynamic(() =>
  import('./InstadeliveryFields/InstadeliveryFields')
);
const ProductsFields = dynamic(() => import('./ProductsFields/ProductsFields'));
const OfferMetaData = dynamic(() => import('./OfferMetaData/OfferMetaData'));

export {
  ProductsGrid,
  ProductsForm,
  SignUpFeeFields,
  FormTitle,
  ProductOverview,
  PricePerKilometersFields,
  InstastartFields,
  InstadeliveryFields,
  ProductsFields,
  OfferMetaData,
};
/* PLOP_INJECT_COMPONENT_EXPORT */
