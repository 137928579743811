import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHandoveredCarsState,
  getHandoveredCars,
  getMoreHandoveredCars,
  changePage,
  initState,
} from '@store';
import { getCleanedUrlParams, showError } from '@utils';

export const useHandoverCarsOperations = (handoverType, fetchOnMount) => {
  const {
    total,
    loading,
    vehicles,
    hasErrors,
    errorMessage,
    page,
    pageCount,
    pageSize,
    groups,
  } = useSelector(selectHandoveredCarsState);
  const {
    isReady,
    query: { location, type, query },
  } = useRouter();
  const dispatch = useDispatch();

  const _computePayload = ({
    currentPage,
    currentLocation,
    currentType,
    currentQuery,
  }) => {
    return getCleanedUrlParams({
      page: currentPage,
      pageSize,
      order: 'desc',
      orderBy: `${handoverType === 0 ? 'delivery' : 'return'}Date`,
      location: currentLocation,
      type: currentType,
      searchFor: handoverType,
      query: currentQuery,
    });
  };

  const _getHandoveredCars = useCallback(
    async (currentPage = 1) => {
      const payload = _computePayload({
        currentPage,
        currentLocation: location,
        currentType: type,
        currentQuery: query,
      });

      await dispatch(getHandoveredCars(payload));
    },
    [dispatch, location, type, query] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _getMoreHandoveredCars = useCallback(
    async (currentPage) => {
      const payload = _computePayload({
        currentPage,
        currentLocation: location,
        currentType: type,
        currentQuery: query,
      });
      await dispatch(getMoreHandoveredCars(payload));
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const fetchMore = () => {
    const nextPage = page + 1;

    if (pageCount >= nextPage) {
      _getMoreHandoveredCars(nextPage);
    }
  };

  useEffect(() => {
    return () => dispatch(initState());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getHandoveredCars();
    }
  }, [_getHandoveredCars, fetchOnMount, isReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    total,
    loading,
    vehicles,
    hasErrors,
    errorMessage,
    page,
    handoverType,
    pageSize,
    groups,
    getHandoveredCars: _getHandoveredCars,
    fetchMore,
    changePage,
  };
};
