import { useRouter } from 'next/router';
import { getCleanedUrlParams, getUrl, showError } from '@utils';
import { QUERY_KEYS } from 'constants/queryKeys';
import { agentsServices, reservationServices } from '@services';
import { useQuery } from 'react-query';
import { URLS } from 'layouts/DrawerLayout/constants';
import { LIMIT } from '@constants';
import { getReservationPayload } from '@helpers';
import { useEffect } from 'react';
import {
  normalizeReservations,
  normalizeReservationsAgents,
  normalizeReservationsEnums,
} from './utils';

export const useReservationsOperations = (enableFetch = true) => {
  const router = useRouter();
  const pageSize = LIMIT;

  const page = router.query?.page || '1';
  const orderBy = router.query?.orderBy || 'createdAt';
  const allFilters = router.query;
  const cleanedParams = getCleanedUrlParams({
    ...allFilters,
    pageSize,
    page,
    orderBy,
  });

  const reservationsResponse = useQuery(
    [QUERY_KEYS.GET_RESERVATIONS_KEY, cleanedParams],
    () => {
      const payload = getReservationPayload({
        ...allFilters,
      });
      return reservationServices.getReservations({
        urlParams: cleanedParams,
        payload,
      });
    },
    { enabled: enableFetch, refetchOnMount: true }
  );

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...router.query, ...sortByModel }, URLS.reservations);
    router.push(url);
  };

  const handlePageChange = (newPage) => {
    const currentPage = router.query.page || '1';
    if (newPage !== currentPage) {
      router.push(
        getUrl({ ...router.query, page: newPage }, URLS.reservations)
      );
    }
  };

  const reservations = normalizeReservations(
    reservationsResponse?.data?.data || []
  );

  const reservationsEnumsResponse = useQuery(
    QUERY_KEYS.GET_RESERVATIONS_ENUMS_KEY,
    () => reservationServices.getReservationsEnums()
  );

  const reservationsEnums = normalizeReservationsEnums(
    reservationsEnumsResponse?.data?.data || []
  );

  const reservationsAgentsResponse = useQuery(QUERY_KEYS.GET_AGENTS_KEY, () =>
    agentsServices.getAgents({
      page: 1,
      pageSize: 1000,
    })
  );

  const reservationsAgents = normalizeReservationsAgents(
    reservationsAgentsResponse?.data?.data || []
  );

  useEffect(() => {
    if (reservationsResponse.isError) {
      showError(reservationsResponse?.error);
    }
  }, [reservationsResponse.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    page,
    total: reservationsResponse?.data?.meta?.total,
    loading: reservationsResponse?.isLoading,
    agentsOptions: reservationsAgents,
    filters: allFilters,
    reservations,
    reservationsEnums,
    pageSize,
    hasErrors: reservationsResponse?.isError,
    errorMessage: reservationsResponse?.error,
    getReservations: reservationsResponse.refetch,
    sortBy: handleSortBy,
    nextPage: handlePageChange,
  };
};
