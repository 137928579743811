import { formatDate } from '@utils';

export const handleVendor = (data) => {
  data.details = {
    id: data?.details?.id || '-',
    name: data?.details?.name,
    email: data?.details?.contact?.email || '-',
    contactObject: {
      ...data?.details?.contact,
    },
    phoneNumber: data?.details?.contact?.phoneNumber?.[0]?.phoneNumber || '-',
    status: data?.details?.status ? 'Active' : 'Inactive',
    logo: data?.details?.logo?.url || '',
    addressObject: {
      ...data?.details?.address,
      lng: data?.details?.address?.longitude,
      lat: data?.details?.address?.latitude,
    },
    address: `${data?.details?.address?.streetAddress || ''} ${
      data?.details?.address?.streetNumber || ''
    },  ${data?.details?.address?.region || ''}, ${
      data?.details?.address?.postalCode || ''
    }`,
    createdAt: data?.details?.createdAt
      ? formatDate(data?.details?.createdAt, 'DD/MM/YYYY')
      : '-',
    updatedAt: data?.details?.updatedAt
      ? formatDate(data?.details?.updatedAt, 'DD/MM/YYYY')
      : '-',
  };

  if (data?.products) {
    const productGroups = data?.products.reduce((groups, item) => {
      const id = item?.sku?.id;
      const group = groups[id] || (groups[id] = []);
      group.push(item);
      return groups;
    }, {});

    // groupItems(data?.products, 'skuId');
    data.products = Object.keys(productGroups)?.map((key, index) => {
      return {
        label: productGroups[key]?.[0]?.sku?.name || `Tab ${index}`, // ΤODO: add SKU name
        key,
        products: productGroups[key],
      };
    });
  }
  return data;
};
