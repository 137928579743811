import { omit } from 'lodash';

import { ARNM_REASONS_MAP, BOARDS_ID } from '@constants';
import { formatDate, sortArray } from '@utils';
import { ARNM_REASONS_MAINTENANCE_MAP } from 'components/arnmMaintenance/constants';

export const handleServicePointsTaskData = (servicePoints) => {
  if (!servicePoints) {
    return [];
  }
  return servicePoints?.map((item) => {
    const primaryContact = item?.contacts?.find(
      (contact) => contact?.isPrimary === true
    );
    return {
      text: item?.name,
      value: item?.id,
      email: primaryContact?.email,
    };
  });
};

const getServicePoint = (servicePoints, servicePointId) => {
  return (
    servicePoints.find((item) => item?.value === servicePointId) || {
      value: '',
      text: '',
    }
  );
};
const getAssignee = (assignees, serviceAssignee) => {
  return (
    assignees.find((item) => item?.value === serviceAssignee) || {
      value: '',
      text: '',
    }
  );
};

export const handleArnmMaintenanceData = (data, assignees) => {
  if (!data.length > 0) {
    return {};
  }

  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.email] = {
      ...cur,
      shortName: `${cur.firstName?.[0] || ''}${
        cur.lastName?.[0] || ''
      }`.toUpperCase(),
    };
    return acc;
  }, {});
  const tasks = [];
  data?.forEach((item) => {
    const assignee = assigneesMap[item.assignee] || {};

    const newTasks = {
      ...item,
      href: `/arnmMaintenance/${item?.id}`,
      status: item?.status,
      assignee,
      description: item?.name,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      date: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : null,
      // TODO: handle custom fields
      id: item?.id,
      colorName: item?.customFields?.doWeNeedATempCar && 'red',
      servicePoint: item?.customFields?.servicePoint || '-',
      reason: ARNM_REASONS_MAP[item?.customFields?.reason] || '-',
      outDateFromServicePoint: item?.customFields?.outDateFromServicePoint
        ? formatDate(item?.customFields?.outDateFromServicePoint, 'DD/MM/YYYY')
        : '-',
    };
    tasks.push(newTasks);
  });

  return {
    boardId: BOARDS_ID.ARM_MAINTENANCE,
    tasks,
  };
};

export const handleArnmMaintenanceTaskData = (
  data,
  servicePoints = [],
  assignees = []
) => {
  if (!data) {
    return {};
  }
  const servicePoint = getServicePoint(
    servicePoints,
    data.customFields.servicePoint
  );
  const assignee = getAssignee(assignees, data.assignee);

  const task = {
    ...data,
    assignee,
    customFields: {
      ...omit(data.customFields, ['comments']),
      reason: ARNM_REASONS_MAINTENANCE_MAP[data.customFields?.reason] || '-',
      servicePoint,
      totalChargeClientAmount:
        data.customFields.totalChargeClientAmount?.number || null,
      totalOfferAmount: data.customFields.totalOfferAmount?.number || null,
      totalInvoiceCost: data.customFields.totalInvoiceCost?.number || null,
      workOrder: data.customFields.workOrder?.map?.((item) => ({
        ...item,
        chargeClientAmount: item.chargeClientAmount?.number || null,
        offerAmount: item.offerAmount?.number || null,
      })),
    },
    changelog: data?.changelog?.reverse(),
    comments: sortArray(data?.comments, 'createdAt', 'desc')?.map((entry) => {
      return {
        id: entry?.id,
        date: entry?.createdAt
          ? formatDate(entry?.createdAt, 'DD MMMM YYYY')
          : '-',
        time: entry?.createdAt ? formatDate(entry?.createdAt, 'HH:mm') : '-',
        name: entry?.userFullName || entry?.user || '-',
        comment: entry?.comment,
        edit: entry?.editedAt
          ? formatDate(entry?.editedAt, 'DD MMMM YYYY HH:mm')
          : '',
      };
    }),
    documents: data?.documents,
  };
  return task;
};

export const handleVehicleData = (data) => {
  if (!data) {
    return {};
  }
  const vehicle = {
    images: data?.images,
    title: `${data?.sku?.brand || ''} ${data?.sku?.model || ''} ${
      data?.sku?.edition || ''
    } ${data?.properties?.color || ''} ${data?.year || ''} - ${
      data?.plate || ''
    }`,
    subTitle: `${data?.internalId ? `${data?.internalId} -` : '' || ''} ${
      data?.vin ? `${data?.vin} -` : ''
    } ${
      data?.procurement?.insuranceProvider
        ? `${data?.procurement?.insuranceProvider} -`
        : ''
    } ${
      data?.properties?.transmission
        ? `${data?.properties?.transmission} -`
        : ''
    } ${data?.properties?.fuelType}`,
    ownershipInfo: `${
      data?.procurement?.vehicleOwnership
        ? `${data?.procurement?.vehicleOwnership} -`
        : ''
    } ${data?.procurement?.financingProvider || ''}`,
  };
  return vehicle;
};

export const handleAssigneeData = (data = []) =>
  data
    ?.sort((a, b) => {
      return a.firstName.localeCompare(b.firstName);
    })
    ?.map((item) => ({
      isActive: item.isActive,
      value: item.email,
      text:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
    }));
