import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { getCleanedUrlParams, showError } from '@utils';
import { agentsServices } from '@services';

import { QUERY_KEYS } from 'constants/queryKeys';
import {
  getAgentsRequestPayload,
  getAgentsUrl,
  handleAgentsData,
} from './utils';

export const AGENTS_PAGE_SIZE = 1000;

export const useAgentsOperations = () => {
  const router = useRouter();
  const page = router.query?.page || '1';
  const searchTerm = router.query?.searchTerm;
  const role = router.query?.role;

  const params = {
    page,
    role,
    searchTerm,
  };
  const urlParams = {
    page,
    pageSize: AGENTS_PAGE_SIZE,
  };

  const cleanParams = getCleanedUrlParams(params);

  const agentResponse = useQuery(
    [QUERY_KEYS.GET_AGENTS_KEY, cleanParams],
    () => {
      const payload = getAgentsRequestPayload(cleanParams);

      return agentsServices.getFilterAgents({ params: urlParams, payload });
    }
  );
  const handlePageChange = (_event, newPage) => {
    router.push(getAgentsUrl({ ...router.query, page: newPage }));
  };

  useEffect(() => {
    if (agentResponse?.error) {
      showError(agentResponse?.error);
    }
  }, [agentResponse?.error]);

  return {
    page,
    total: agentResponse?.data?.meta?.total,
    error: agentResponse?.error,
    isSuccess: agentResponse?.isSuccess,
    loading: agentResponse?.isLoading,
    pageSize: AGENTS_PAGE_SIZE,
    agents: handleAgentsData(agentResponse?.data?.data),
    refresh: agentResponse.refetch,
    handlePageChange,
  };
};
