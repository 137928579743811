import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getGreenCardTask, selectGreenCardState } from '@store';
import { showError } from '@utils';

export const useGreenCardTaskOperations = () => {
  const { greenCardTask, vehicle, loading, hasErrors, errorMessage } =
    useSelector(selectGreenCardState);
  const {
    query: { greenCardTaskId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getGreenCardTask = useCallback(async () => {
    await dispatch(getGreenCardTask(greenCardTaskId));
  }, [greenCardTaskId, dispatch]);

  useEffect(() => {
    if (greenCardTaskId !== undefined) {
      _getGreenCardTask();
    }
  }, [greenCardTaskId, _getGreenCardTask, token]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    task: greenCardTask,
    taskId: greenCardTaskId,
    hasErrors,
    errorMessage,
    getGreenCardTask: _getGreenCardTask,
    token,
    vehicle,
  };
};
