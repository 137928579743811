import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { LIMIT } from '@constants';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { roadsideAssistanceServices } from '@services';
import { handleRoadsideAssistancesData } from './utils';

export const useRoadsideAssistancesOperations = () => {
  const {
    push,
    query: {
      page = 1,
      pageSize = LIMIT,
      orderBy = 'internalId',
      order = 'asc',
    },
    isReady,
  } = useRouter();

  const roadsideAssistancesResponse = useQuery(
    [
      QUERY_KEYS.GET_ROADSIDE_ASSISTANCES_KEY,
      {
        page,
        pageSize,
        order,
        orderBy,
      },
    ],
    () =>
      roadsideAssistanceServices?.getRoadsideAssistances({
        page,
        pageSize,
        order,
        orderBy,
      }),
    { enabled: isReady }
  );

  const roadsideAssistances = handleRoadsideAssistancesData(
    roadsideAssistancesResponse?.data?.data || []
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/roadsideAssistance?orderBy=${
        sortingModel?.orderBy || ''
      }&order=${sortingModel?.order || ''}&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/roadsideAssistance?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/roadsideAssistance?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/roadsideAssistance?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    if (roadsideAssistancesResponse?.error) {
      showError(roadsideAssistancesResponse?.error);
    }
  }, [roadsideAssistancesResponse?.error]);

  return {
    page,
    pageSize,
    total: roadsideAssistancesResponse?.data?.meta?.total,
    loading: roadsideAssistancesResponse?.isLoading,
    roadsideAssistances,
    getRoadsideAssistances: roadsideAssistancesResponse.refetch,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
