import { request } from '@utils';
import { LIMIT } from '@constants';

const getBoardsEnum = async () => {
  const results = await request.get(`/v1/boards/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getBoardsByType = async (type) => {
  const results = await request.get(
    `/v1/boards/type/${type}?page=1&pageSize=1000`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getBoardsFilters = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload.order) {
    payload.order = 'asc';
    payload.orderBy = 'status';
  }

  const boardId = payload?.boardId;
  const boardType = payload?.boardType;
  const queryParams = `?page=${payload?.page}&pageSize=${payload?.pageSize}&order=${payload?.order}&orderBy=${payload?.orderBy}&boardType=${boardType}`;
  delete payload?.pageSize;
  delete payload?.page;
  delete payload?.order;
  delete payload?.orderBy;
  delete payload?.boardId;
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/boards/${boardId}/tasks/filter${queryParams}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getBillingBoardsFilters = async ({ boardId, params, payload }) => {
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/boards/${boardId}/tasks/filter?${new URLSearchParams(params)}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addTask = async (boardId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.post(`/v1/boards/${boardId}/tasks`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateTask = async (taskId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(`/v1/tasks/${taskId}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const moveTaskBoard = async (taskId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.post(`/v1/tasks/${taskId}/move`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getTaskById = async (taskId) => {
  const results = await request.get(`/v1/tasks/${taskId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getSubTasksById = async (taskId) => {
  const results = await request.get(`/v1/tasks/${taskId}/sub-tasks`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addTaskComment = async (taskId, comment) => {
  const params = {
    body: { comment },
  };
  const results = await request.post(`/v1/tasks/${taskId}/comments`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editTaskComment = async (taskId, commentId, comment) => {
  const params = {
    body: { comment },
  };
  const results = await request.patch(
    `/v1/tasks/${taskId}/comments/${commentId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateTaskDocument = async (taskId, docId, payload) => {
  const params = {
    body: payload,
  };

  const results = await request.patch(
    `/v1/tasks/${taskId}/documents/${docId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteTaskComment = async (taskId, commentId) => {
  const results = await request.remove(
    `/v1/tasks/${taskId}/comments/${commentId}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const uploadTaskDocument = async (
  taskId,
  documents,
  documentType,
  tags = []
) => {
  const fd = new FormData();
  fd.append('documents', documents);
  fd.append('type', documentType);
  if (tags?.length) {
    tags?.forEach((element) => {
      fd.append('tags', element);
    });
  }

  const body = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(`/v1/tasks/${taskId}/documents`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteTaskDocument = async (taskId, docId) => {
  const results = await request.remove(
    `/v1/tasks/${taskId}/documents/${docId}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateTaskImage = async (taskId, docId, payload) => {
  const params = {
    body: payload,
  };

  const results = await request.patch(
    `/v1/tasks/${taskId}/images/${docId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const uploadTaskImage = async (taskId, images, tags = []) => {
  const fd = new FormData();
  fd.append('images', images);
  if (tags?.length) {
    tags?.forEach((element) => {
      fd.append('tags', element);
    });
  }

  const body = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(`/v1/tasks/${taskId}/images`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteTaskImage = async (taskId, imageId) => {
  const results = await request.remove(`/v1/tasks/${taskId}/images/${imageId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const makeAction = async (taskId, actionId, payload = {}) => {
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/tasks/${taskId}/actions/${actionId}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const sendOffer = async (taskId) => {
  const results = await request.get(
    `/v1/www/vehicle-sales/send-offer/${taskId}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const exportFile = async ({ boardId, payload = {} }) => {
  const body = {
    body: payload,
    responseType: 'text',
  };

  const results = await request.post(`/v1/boards/${boardId}/export`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getBoardsEnum,
  getBoardsByType,
  addTask,
  getTaskById,
  getSubTasksById,
  updateTask,
  uploadTaskDocument,
  deleteTaskDocument,
  addTaskComment,
  editTaskComment,
  deleteTaskComment,
  deleteTaskImage,
  uploadTaskImage,
  getBoardsFilters,
  updateTaskDocument,
  updateTaskImage,
  getBillingBoardsFilters,
  makeAction,
  moveTaskBoard,
  exportFile,
  sendOffer,
};
