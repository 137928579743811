import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getCleanedUrlParams, getUrl, showError } from '@utils';
import { LIMIT } from '@constants';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { vendorsServices } from '@services';
import { URLS } from 'layouts/DrawerLayout/constants';
import { getVendorsQueryParams, normalizeTeams } from './utils';

export const useVendorsOperations = (enableFetch = true) => {
  const router = useRouter();
  const pageSize = LIMIT;

  const page = router.query?.page || 1;
  const order = router.query?.order;
  const orderBy = router.query?.orderBy;

  const params = {
    orderBy,
    order,
    page,
  };

  const cleanParams = getVendorsQueryParams(params);

  const vendorsResponse = useQuery(
    [QUERY_KEYS.GET_VENDORS_KEY, cleanParams],
    () => vendorsServices.getVendors(getCleanedUrlParams(params)),
    { enabled: enableFetch, refetchOnMount: true }
  );

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...router.query, ...sortByModel }, URLS.vendors);
    router.push(url);
  };

  const handlePageChange = (_event, newPage) => {
    router.push(getUrl({ ...router.query, page: newPage }), URLS.vendors);
  };

  const vendors = normalizeTeams(vendorsResponse?.data?.data || []);

  useEffect(() => {
    if (vendorsResponse.isError) {
      showError(vendorsResponse?.error);
    }
  }, [vendorsResponse.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    page,
    pageSize,
    total: vendorsResponse?.data?.meta?.total || 0,
    loading: vendorsResponse?.isLoading,
    vendors,
    hasError: vendorsResponse?.isError,
    errorMessage: vendorsResponse?.error,
    getVendors: vendorsResponse.refetch,
    nextPage: handlePageChange,
    sortBy: handleSortBy,
  };
};
