import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { ebikesServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { asyncForEach } from '@utils';
import {
  handleEbikes,
  handleEbikeData,
  handleEbikesBySku,
} from './ebike.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  ebikes: [],
  ebike: {
    ebikesBySku: {
      loading: false,
      data: [],
      errorMessage: '',
      total: 0,
      page: 1,
      pageCount: 0,
      firstItem: 1,
      lastItem: LIMIT,
      pageSize: LIMIT,
    },
  },
  total: 0,
  pageSize: LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const ebikesSlice = createSlice({
  name: 'ebikes',
  initialState,
  reducers: {
    fetchEbikes: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchEbikesSuccess: (state, { payload }) => {
      state.ebikes = handleEbikes(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchEbikesFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchEbike: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.ebike = {};
    },
    fetchEbikeSuccess: (state, { payload }) => {
      state.ebike = handleEbikeData(payload);
      state.ebike.ebikesBySku = initialState.ebike.ebikesBySku;
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchEbikeFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchEbikesBySku: (state) => {
      if (!state.ebike.ebikesBySku) {
        state.ebike.ebikesBySku = { ...initialState.ebike.ebikesBySku };
      }
      state.ebike.ebikesBySku.errorMessage = '';
      state.ebike.ebikesBySku.loading = true;
    },
    fetchEbikesBySkuSuccess: (state, { payload }) => {
      state.ebike.ebikesBySku.data = handleEbikesBySku(payload.data);
      state.ebike.ebikesBySku.errorMessage = '';
      state.ebike.ebikesBySku.loading = false;
      state.ebike.ebikesBySku.total = payload?.meta?.total || 0;
      state.ebike.ebikesBySku.page = payload?.meta?.page;

      state.ebike.ebikesBySku.firstItem =
        payload?.meta?.page === 1
          ? 1
          : ((payload?.meta?.page || 1) - 1) *
              state.ebike.ebikesBySku.pageSize +
            1;
      state.ebike.ebikesBySku.lastItem =
        payload?.meta?.page === 1
          ? state.ebike.ebikesBySku.data.length
          : ((payload?.meta?.page || 1) - 1) *
              state.ebike.ebikesBySku.pageSize +
            (state.ebike.ebikesBySku.data.length || 0);

      state.ebike.ebikesBySku.pageCount =
        payload?.meta?.total < state.ebike.ebikesBySku.pageSize
          ? 1
          : Math.ceil(
              (payload?.meta?.total || 1) / state.ebike.ebikesBySku.pageSize
            );
    },
    fetchEbikesBySkuFailure: (state, { payload }) => {
      state.ebike.ebikesBySku.errorMessage = payload || GENERIC_ERROR_MESSAGE;
      state.ebike.ebikesBySku.loading = false;
    },
    initializePostEbike: (state) => {
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
      state.postSuccess = false;
      const ebike = { ...state.ebike };
      if (ebike?.ebike) {
        ebike.ebike = {};
      }
      state.ebike = ebike;
    },
    postEbike: (state) => {
      state.postLoading = true;
      state.postHasErrors = false;
      state.postErrorMessage = '';
      state.postSuccess = false;
    },
    postEbikeSuccess: (state) => {
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
      state.postSuccess = true;
    },
    postEbikeFailure: (state, { payload }) => {
      state.postLoading = false;
      state.postHasErrors = true;
      state.postErrorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    deleteEbikeImageSuccess: (state, { payload }) => {
      const ebike = { ...state?.ebike };

      if (ebike?.ebike?.images?.length) {
        const images = ebike?.ebike?.images?.filter((image) => {
          return image?.id !== payload;
        });
        ebike.ebike.images = images;
      }
      state.ebike = ebike;
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
    },

    deleteEbikeFileSuccess: (state, { payload }) => {
      const ebike = { ...state?.ebike };

      if (ebike?.ebike?.docs?.length) {
        const docs = ebike?.ebike?.docs?.filter((doc) => {
          return doc?.id !== payload;
        });
        ebike.ebike.docs = docs;
      }
      state.ebike = ebike;
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchEbikes,
  fetchEbikesSuccess,
  fetchEbikesFailure,
  fetchEbike,
  fetchEbikeSuccess,
  fetchEbikeFailure,
  postEbike,
  postEbikeSuccess,
  postEbikeFailure,
  initializePostEbike,
  deleteEbikeImageSuccess,
  deleteEbikeFileSuccess,
  fetchEbikesBySkuSuccess,
  fetchEbikesBySku,
  fetchEbikesBySkuFailure,
} = ebikesSlice.actions;

export const selectEbikesState = (state) => state.ebikes;

export const getEbikes = (payload) => {
  return async (dispatch) => {
    dispatch(fetchEbikes());
    const page = parseInt(payload?.page, 10);
    try {
      const response = await ebikesServices?.getEbikesFilters(payload);
      response.meta.page = page;
      dispatch(fetchEbikesSuccess(response));
    } catch (error) {
      dispatch(fetchEbikesFailure(error));
    }
  };
};

export const getEbikesBySku = (
  ebikeId,
  params = { page: 1, pageSize: LIMIT }
) => {
  return async (dispatch) => {
    dispatch(fetchEbikesBySku());
    const page = parseInt(params?.page, 10);

    try {
      if (ebikeId) {
        const response = await ebikesServices?.getEbikesBySku(ebikeId, params);
        response.meta.page = page;
        dispatch(fetchEbikesBySkuSuccess(response));
      }
    } catch (error) {
      dispatch(fetchEbikesBySkuFailure(error));
    }
  };
};

export const getEbike = (payload) => {
  return async (dispatch) => {
    dispatch(fetchEbike());

    try {
      const data = {};
      if (payload) {
        data.ebike = await ebikesServices?.getEbike(payload);
      }
      data.enums = await ebikesServices?.getEbikeEnums();
      data.providers = await ebikesServices?.getEbikeProviders();
      data.models = await ebikesServices?.getEbikesModels();
      dispatch(fetchEbikeSuccess(data));
    } catch (error) {
      dispatch(fetchEbikeFailure(error));
    }
  };
};

export const addEbike = (payload) => {
  return async (dispatch) => {
    dispatch(postEbike());

    try {
      const { skuId } = payload.form;
      await ebikesServices.postEbike(payload.form);

      if (payload.pricing?.length) {
        await asyncForEach(payload?.pricing, async (prices) => {
          if (prices?.id) {
            await ebikesServices.updatePricing(skuId, prices?.id, prices);
          } else {
            await ebikesServices.addPricing(skuId, [prices]);
          }
        });
      }
      if (payload.docs?.length) {
        await asyncForEach(payload?.docs, async (doc) => {
          ebikesServices.postEbikeFile(skuId, doc.file);
        });
      }

      dispatch(postEbikeSuccess());
    } catch (error) {
      dispatch(postEbikeFailure(error));
    }
  };
};

export const editEbike = (payload) => {
  return async (dispatch) => {
    dispatch(postEbike());

    try {
      const { skuId } = payload.form;
      const { primaryImageId } = payload;
      const data = {
        ...payload.form,
        images: [...payload.images],
        documents: [...payload.docs],
      };
      if (payload.pricing?.length) {
        await asyncForEach(payload?.pricing, async (prices) => {
          if (!prices?.insert) {
            await ebikesServices.updatePricing(skuId, prices?.id, prices);
          } else {
            await ebikesServices.addPricing(skuId, [prices]);
          }
        });
      }
      await ebikesServices.updateEbike(skuId, data);
      if (primaryImageId) {
        await ebikesServices.postEbikePrimaryImage(skuId, primaryImageId);
      }
      // if (payload.pricing?.length) {
      //   await asyncForEach(payload?.pricing, async (prices) => {
      //     if (prices?.id) {
      //       await ebikesServices.updatePricing(skuId, prices?.id, prices);
      //     } else {
      //       await ebikesServices.addPricing(skuId, [prices]);
      //     }
      //   });
      // }
      dispatch(postEbikeSuccess());
    } catch (error) {
      dispatch(postEbikeFailure(error));
    }
  };
};

export const deleteEbikeImage = (payload) => {
  return async (dispatch) => {
    dispatch(postEbike());

    try {
      await ebikesServices.deleteEbikeImage(payload);

      dispatch(deleteEbikeImageSuccess(payload?.id));
    } catch (error) {
      dispatch(postEbikeFailure(error));
    }
  };
};

export const deleteEbikeFile = (payload) => {
  return async (dispatch) => {
    dispatch(postEbike());

    try {
      await ebikesServices.deleteEbikeFile(payload);

      dispatch(deleteEbikeFileSuccess(payload?.id));
    } catch (error) {
      dispatch(postEbikeFailure(error));
    }
  };
};

export default ebikesSlice.reducer;
