import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectNotificationsState,
  getNotifications,
  selectLoginState,
  postViewedNotifications,
  setParams,
} from '@store';
import { POLLING } from '@constants';
import { cloneDeep, cloneDeepWith } from 'lodash';

export const useNotificationsOperations = (fetchOnMount) => {
  const {
    total,
    loading,
    readNumber,
    unreadNumber,
    notifications,
    pageCount,
    hasErrors,
    params,
    errorMessage,
    pageSize,
  } = useSelector(selectNotificationsState);
  const { isLoggedIn } = useSelector(selectLoginState);
  const {
    query: { page = 1, token, query = '' },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();
  const _getNotifications = useCallback(async () => {
    const paramsCopy = cloneDeepWith(params);
    if (paramsCopy && paramsCopy.origin === undefined) {
      delete paramsCopy.origin;
    }
    if (paramsCopy && paramsCopy.type === undefined) {
      delete paramsCopy.type;
    }
    if (paramsCopy && paramsCopy.viewed === undefined) {
      delete paramsCopy.viewed;
    }
    await dispatch(getNotifications(paramsCopy));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params), dispatch]);

  const _notificationIsViewed = useCallback(
    async (payload) => {
      await dispatch(
        postViewedNotifications({
          notificationIds: payload,
        })
      );
    },
    [dispatch]
  );

  const _setParams = (key, value) => {
    if (!key || !params) return;
    const newParams = cloneDeep(params);
    newParams[key] = value ?? undefined;
    dispatch(setParams(newParams));
  };

  useEffect(() => {
    let id = '';
    if (fetchOnMount && isReady && isLoggedIn) {
      _getNotifications();
      id = setInterval(_getNotifications, POLLING);
    }
    return () => clearInterval(id);
  }, [_getNotifications, token, fetchOnMount, isReady, isLoggedIn]);

  return {
    page,
    total,
    loading,
    notifications,
    pageCount,
    hasErrors,
    errorMessage,
    pageSize,
    getNotifications: _getNotifications,
    token,
    query,
    readNumber,
    unreadNumber,
    notificationIsViewed: _notificationIsViewed,
    setParams: _setParams,
    params,
  };
};
