import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { insurancesServices } from '@services';
import { GENERIC_ERROR_MESSAGE } from '@constants';
import { handleInsuranceData } from './insurances.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  insurance: {},
};
// Actual Slice
export const insurancesSlice = createSlice({
  name: 'insurances',
  initialState,
  reducers: {
    fetchInsurance: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchInsuranceSuccess: (state, { payload }) => {
      state.insurance = handleInsuranceData(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchInsuranceFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const { fetchInsurance, fetchInsuranceSuccess, fetchInsuranceFailure } =
  insurancesSlice.actions;

export const selectInsurancesState = (state) => state.insurances;

export const getInsurance = (id) => {
  return async (dispatch) => {
    dispatch(fetchInsurance());

    try {
      const response = await insurancesServices?.getInsurance(id);

      dispatch(fetchInsuranceSuccess(response));
    } catch (error) {
      dispatch(fetchInsuranceFailure(error));
    }
  };
};

export default insurancesSlice.reducer;
