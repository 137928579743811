import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectServicePointsState,
  getServicePoint,
  getServicePointEvents,
  getServicePointsEnums,
} from '@store';
import { showError } from '@utils';

export const useServicePointOperations = (onMount) => {
  const {
    total,
    loading,
    servicePoint,
    events,
    eventsLoading,
    hasErrors,
    errorMessage,
    enums,
  } = useSelector(selectServicePointsState);
  const {
    query: { servicePointId, token, tab },
  } = useRouter();
  const dispatch = useDispatch();

  const _getServicePoint = useCallback(async () => {
    await dispatch(getServicePoint(servicePointId));
  }, [servicePointId, dispatch]);

  const _getServicePointEvents = useCallback(async () => {
    await dispatch(getServicePointEvents(servicePointId));
  }, [servicePointId, dispatch]);

  const _getServicePointsEnums = useCallback(async () => {
    await dispatch(getServicePointsEnums());
  }, [dispatch]);

  useEffect(() => {
    if (servicePointId !== undefined && onMount) {
      _getServicePoint();
    }
  }, [servicePointId, _getServicePoint, token, onMount]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    total,
    loading,
    servicePoint,
    servicePointId,
    events,
    eventsLoading,
    hasErrors,
    errorMessage,
    getServicePoint: _getServicePoint,
    getServicePointEvents: _getServicePointEvents,
    token,
    enums,
    getServicePointsEnums: _getServicePointsEnums,
    tab,
  };
};
