import dynamic from 'next/dynamic';

const ArnmRepairsGrid = dynamic(() =>
  import('./ArnmRepairsGrid/ArnmRepairsGrid')
);
const AddArnmRepairsModal = dynamic(() =>
  import('./AddArnmRepairsModal/AddArnmRepairsModal')
);
const UploadArnmRepairsDocument = dynamic(() =>
  import('./UploadArnmRepairsDocument/UploadArnmRepairsDocument')
);
const ArnmRepairsVehicleHeader = dynamic(() =>
  import('./ArnmRepairsVehicleHeader/ArnmRepairsVehicleHeader')
);
const ChangeArnmRepairsStage = dynamic(() =>
  import('./ChangeArnmRepairsStage/ChangeArnmRepairsStage')
);
const ArnmRepairsFilter = dynamic(() =>
  import('./ArnmRepairsFilter/ArnmRepairsFilter')
);
const RepairsForm = dynamic(() => import('./RepairsForm/RepairsForm'));

export {
  ArnmRepairsGrid,
  AddArnmRepairsModal,
  UploadArnmRepairsDocument,
  ArnmRepairsVehicleHeader,
  ChangeArnmRepairsStage,
  ArnmRepairsFilter,
  RepairsForm,
};
