import dynamic from 'next/dynamic';

const EbikesGrid = dynamic(() => import('./EbikesGrid/EbikesGrid'));
const EbikeFilters = dynamic(() => import('./EbikeFilters/EbikeFilters'));
const EbikeHeader = dynamic(() => import('./EbikeHeader/EbikeHeader'));
const EbikeOverview = dynamic(() => import('./EbikeOverview/EbikeOverview'));
const EbikeSku = dynamic(() => import('./EbikeSku/EbikeSku'));
const EbikeForm = dynamic(() => import('./EbikeForm/EbikeForm'));
const EbikePricingSection = dynamic(() =>
  import('./EbikePricingSection/EbikePricingSection')
);
const EbikePropertiesSection = dynamic(() =>
  import('./EbikePropertiesSection/EbikePropertiesSection')
);
const EbikePageContent = dynamic(() =>
  import('./EbikePageContent/EbikePageContent')
);
const EbikePricingGrid = dynamic(() =>
  import('./EbikePricingGrid/EbikePricingGrid')
);

export {
  EbikesGrid,
  EbikeFilters,
  EbikeHeader,
  EbikeOverview,
  EbikeSku,
  EbikeForm,
  EbikePricingSection,
  EbikePropertiesSection,
  EbikePageContent,
  EbikePricingGrid,
};
