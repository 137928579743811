import { BOARDS_ID } from '@constants';
import { formatDate } from '@utils';
import { ARNM_REASONS_REPAIRS_MAP } from 'components/arnmRepairs/constants';

export const handleArnmRepairsData = (data, assignees) => {
  if (!data.length) {
    return {};
  }
  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.email] = {
      ...cur,
      shortName: `${cur.firstName?.[0] || ''}${
        cur.lastName?.[0] || ''
      }`.toUpperCase(),
    };
    return acc;
  }, {});

  const tasks = data.map((item) => {
    const assignee = assigneesMap[item.assignee] || {};
    return {
      ...item,
      href: `/arnmRepairs/${item?.id}`,
      plate: item.customFields?.vehiclePlate || '',
      status: item?.status,
      assignee,
      description: item?.customFields?.vehicleDescription || '-',
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      date: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : null,
      // TODO: handle custom fields
      id: item?.id,
      servicePoint: item?.customFields?.servicePoint || '-',
      reason: ARNM_REASONS_REPAIRS_MAP[item?.customFields?.reason] || '-',
      outDateFromServicePoint: item?.customFields?.outDateFromServicePoint
        ? formatDate(item?.customFields?.outDateFromServicePoint, 'DD/MM/YYYY')
        : '-',
    };
  });

  return {
    boardId: BOARDS_ID.ARM_REPAIRS,
    tasks,
    // columns,
  };
};

export const handleAssigneeData = (data = []) =>
  data
    .map((item) => ({
      value: item.email,
      text:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
    }))
    ?.sort((a, b) => a.text.localeCompare(b.text));
