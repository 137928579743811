import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSubscriptionsState,
  getSubscriptions,
  filtersModalChange,
  resetModalChange,
} from '@store';
import { getCleanedUrlParams, getUrl, showError } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';

export const useSubscriptionsOperations = (fetchOnMount) => {
  const {
    total,
    loading,
    subscriptions,
    hasErrors,
    errorMessage,
    filters,
    pageSize,
  } = useSelector(selectSubscriptionsState);

  const dispatch = useDispatch();

  const { push, query, isReady } = useRouter();

  const { page = '1', orderBy = '', order = '', status, type } = query;

  const _getSubscriptions = useCallback(
    async (urlParams, modalFiltersRules) => {
      await dispatch(
        getSubscriptions({
          urlParams: getCleanedUrlParams(urlParams),
          modalFiltersRules,
        })
      );
    },
    [page, orderBy, order, status, type, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _filtersModalChange = useCallback(
    async (conditions) => {
      if (!conditions?.rules?.length) {
        await dispatch(
          getSubscriptions({
            urlParams: getCleanedUrlParams(query),
            modalFiltersRules: filters.modalConditions?.rules?.length
              ? null
              : filters.modalFiltersRules,
          })
        );
      }
      dispatch(filtersModalChange(conditions));
    },
    [dispatch, query] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _resetModalChange = useCallback(() => {
    dispatch(resetModalChange());
  }, [dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      const params = {
        ...sortingModel,
        page,
        status,
        type,
      };

      const url = getUrl(params, URLS.subscriptions);

      push(url, undefined, { shallow: true });
      _getSubscriptions(params, filters.modalFiltersRules);
    },
    [push, page, orderBy, order] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _nextPage = useCallback(
    (currentPage) => {
      const params = {
        orderBy,
        order,
        page: currentPage,
        status,
        type,
      };
      const url = getUrl(params, URLS.subscriptions);

      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
      _getSubscriptions(params, filters.modalFiltersRules);
    },
    [push] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _initialize = async () => {
    await _getSubscriptions(query, filters.modalFiltersRules);
  };

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _initialize();
    }
  }, [isReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    pageSize,
    total,
    loading,
    subscriptions,
    hasErrors,
    errorMessage,
    filters,
    getSubscriptions: _getSubscriptions,
    nextPage: _nextPage,
    sortBy: _sortBy,
    filtersModalChange: _filtersModalChange,
    resetModalChange: _resetModalChange,
  };
};
