/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectProductsState, getProduct } from '@store';
import { showError } from '@utils';

export const useProductOperations = (firstMount) => {
  const { loading, product, getProducthasErrors, getProductErrorMessage } =
    useSelector(selectProductsState);
  const {
    back,
    query: { productId },
  } = useRouter();
  const dispatch = useDispatch();

  const _getProduct = useCallback(async () => {
    if (productId) {
      await dispatch(getProduct(productId));
    }
  }, [productId, dispatch]);

  useEffect(() => {
    if (productId && firstMount) {
      _getProduct();
    }
  }, [productId, _getProduct]);

  useEffect(() => {
    if (getProductErrorMessage) {
      showError(getProductErrorMessage);
    }
  }, [getProductErrorMessage]);

  return {
    loading,
    productId,
    product,
    getProducthasErrors,
    getProductErrorMessage,
    getProduct: _getProduct,
    goBack: back,
  };
};
