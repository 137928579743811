/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectReservationsState, getReservation } from '@store';
import { showError } from '@utils';

export const useReservationOperations = (firstMount) => {
  const {
    loading,
    reservation,
    getReservationhasErrors,
    getReservationErrorMessage,
    history,
    agents,
  } = useSelector(selectReservationsState);
  const {
    back,
    query: { reservationId },
  } = useRouter();
  const dispatch = useDispatch();

  const _getReservation = useCallback(async () => {
    if (reservationId) {
      await dispatch(getReservation(reservationId));
    }
  }, [reservationId, dispatch]);

  useEffect(() => {
    if (reservationId && firstMount) {
      _getReservation();
    }
  }, [reservationId, _getReservation]);

  useEffect(() => {
    if (getReservationErrorMessage) {
      showError(getReservationErrorMessage);
    }
  }, [getReservationErrorMessage]);

  return {
    loading,
    reservationId,
    reservation,
    getReservationhasErrors,
    getReservationErrorMessage,
    getReservation: _getReservation,
    goBack: back,
    history,
    agents,
  };
};
