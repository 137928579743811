import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  agentsServices,
  boardsServices,
  fleetVehiclesServices,
  sellVehiclesServices,
} from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import {
  handleAssigneeData,
  handleSellRequestsTaskData,
  handleVehicleData,
} from './sellRequests.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  sellRequests: [],
  sellRequestsTask: {},
  vehicle: {},
  total: 0,
  assignees: [],
  pageSize: LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const sellRequestsSlice = createSlice({
  name: 'sellRequests',
  initialState,
  reducers: {
    fetchSellRequests: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.returnTask = {};
      state.subTasks = [];
      state.sellRequestsTask = {};
    },
    fetchSellRequestsTaskSuccess: (state, { payload }) => {
      state.sellRequestsTask = handleSellRequestsTaskData(
        payload?.task,
        payload?.assignees?.data
      );
      state.vehicle = handleVehicleData(payload?.vehicle);
      state.assignees = handleAssigneeData(payload?.assignees?.data);
      state.loading = false;
      state.errorMessage = '';
    },
    fetchSellRequestsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchSellRequests,
  fetchSellRequestsTaskSuccess,
  fetchSellRequestsFailure,
} = sellRequestsSlice.actions;

export const selectSellRequestsState = (state) => state.sellRequests;

export const getSellRequestsTask = (taskId) => {
  return async (dispatch) => {
    dispatch(fetchSellRequests());

    try {
      const task = await boardsServices.getTaskById(taskId);
      const saleId = task?.data?.customFields?.saleId; // is not instacar vehicle
      const carId = task?.data?.customFields?.carId; // is instacar vehicle
      let vehicle = {};
      let instacarVehicle = {};
      if (saleId) {
        vehicle = await sellVehiclesServices.getSellVehicle(saleId);
      }
      if (carId) {
        instacarVehicle = await fleetVehiclesServices.getVehicleByInternalId(
          carId
        );
      }
      const assignees = await agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      });
      dispatch(
        fetchSellRequestsTaskSuccess({
          task: task?.data,
          vehicle: {
            ...vehicle?.data,
            procurement: instacarVehicle?.data?.procurement,
          },
          assignees,
        })
      );
    } catch (error) {
      dispatch(fetchSellRequestsFailure(error));
    }
  };
};

export default sellRequestsSlice.reducer;
