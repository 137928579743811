import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUsersState,
  getUser,
  getUserWithSubs,
  getUserSubscriptions,
} from '@store';
import { showError } from '@utils';

export const useUserOperations = (fetchOnMount) => {
  const {
    total,
    loading,
    user,
    userWithSubs,
    subscriptions,
    hasErrors,
    errorMessage,
  } = useSelector(selectUsersState);
  const {
    query: { userId, token },
  } = useRouter();
  const dispatch = useDispatch();
  const _getUserWithSubs = useCallback(async () => {
    if (userId && userId !== 'undefined' && fetchOnMount) {
      await dispatch(getUserWithSubs(userId));
    }
  }, [userId, dispatch, fetchOnMount]);

  const _getUserSubscriptions = useCallback(async () => {
    if (userId && userId !== 'undefined' && fetchOnMount) {
      await dispatch(getUserSubscriptions(userId));
    }
  }, [userId, dispatch, fetchOnMount]);

  const _getUser = useCallback(async () => {
    if (userId && userId !== 'undefined' && fetchOnMount) {
      await dispatch(getUser(userId));
    }
  }, [userId, dispatch, fetchOnMount]);

  useEffect(() => {
    if (userId && userId !== 'undefined' && fetchOnMount) {
      _getUser();
      _getUserWithSubs();
      _getUserSubscriptions();
    }
  }, [
    userId,

    _getUser,
    _getUserSubscriptions,
    _getUserWithSubs,
    token,
    fetchOnMount,
  ]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    total,
    loading,
    user,
    userId,
    hasErrors,
    errorMessage,
    token,
    userWithSubs,
    subscriptions,
    getUser: _getUser,
    getUserWithSubs: _getUserWithSubs,
    getUserSubscriptions: _getUserSubscriptions,
  };
};
