import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { notificationsServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';

import { handleNotifications } from './notifications.handlers';

export const NOTIFICATIONS_PAGE_SIZE = 100;
// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  notifications: [],
  total: 0,
  readNumber: 0,
  unreadNumber: 0,
  pageCount: 0,
  pageSize: LIMIT,
  params: {
    page: 1,
    pageSize: NOTIFICATIONS_PAGE_SIZE,
    orderBy: 'createdAt',
    order: 'desc',
    viewed: false,
  },
};
// Actual Slice
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetchNotifications: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchNotificationsSuccess: (state, { payload }) => {
      state.notifications = handleNotifications(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.readNumber = payload?.data?.filter(
        (notif) => notif?.viewed
      )?.length;
      state.unreadNumber = payload?.data?.filter(
        (notif) => !notif?.viewed
      )?.length;
    },
    fetchNotificationsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    setParams: (state, { payload }) => {
      state.params = payload;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchNotifications,
  fetchNotificationsSuccess,
  fetchNotificationsFailure,
  setParams,
} = notificationsSlice.actions;

export const selectNotificationsState = (state) => state.notifications;

export default notificationsSlice.reducer;

export const getNotifications = (payload) => {
  return async (dispatch) => {
    dispatch(fetchNotifications());

    try {
      const response = await notificationsServices?.getNotifications(payload);

      dispatch(fetchNotificationsSuccess(response));
    } catch (error) {
      dispatch(fetchNotificationsFailure(error));
    }
  };
};

export const postViewedNotifications = (payload) => {
  return async (dispatch) => {
    dispatch(fetchNotifications());

    try {
      await notificationsServices?.notificationIsViewed(payload);
      dispatch(getNotifications());
    } catch (error) {
      dispatch(fetchNotificationsFailure(error));
    }
  };
};
