import theme from 'config/theme';

export const ARNM_ACCIDENTS_TASK_STATUSES_NAMES = {
  0: {
    label: 'Backlog',
    styles: {
      backgroundColor: theme.palette.grays.gray200,
      color: theme.palette.grays.gray900,
    },
  },
  1: {
    label: 'Todo',
    styles: {
      backgroundColor: theme.palette.mints.mint200,
      color: theme.palette.mints.mint900,
    },
  },
  2: {
    label: 'In Progress',
    styles: {
      backgroundColor: theme.palette.yellows.yellow200,
      color: theme.palette.yellows.yellow900,
    },
  },
  13: {
    label: 'Service Point',
    styles: {
      backgroundColor: theme.palette.violets.violet200,
      color: theme.palette.violets.violet900,
    },
  },
  14: {
    label: 'Out Date',
    styles: {
      backgroundColor: theme.palette.reds.red200,
      color: theme.palette.reds.red900,
    },
  },
  16: {
    label: 'Documentation',
    styles: {
      backgroundColor: theme.palette.purples.purple200,
      color: theme.palette.purples.purple900,
    },
  },
  17: {
    label: 'Charge Client',
    styles: {
      backgroundColor: theme.palette.limes.lime200,
      color: theme.palette.limes.lime900,
    },
  },
  18: {
    label: 'Documentation Insurance Company',
    styles: {
      backgroundColor: theme.palette.pinks.pink200,
      color: theme.palette.pinks.pink900,
    },
  },
  19: {
    label: 'Waiting Receipt',
    styles: {
      backgroundColor: theme.palette.blues.babyBlue200,
      color: theme.palette.blues.babyBlue900,
    },
  },
  20: {
    label: 'More Actions',
    styles: {
      backgroundColor: theme.palette.blues.blue200,
      color: theme.palette.blues.blue900,
    },
  },
  21: {
    label: 'Insurance Payment',
    styles: {
      backgroundColor: theme.palette.teals.teal200,
      color: theme.palette.teals.teal900,
    },
  },
  3: {
    label: 'Done',
    styles: {
      backgroundColor: theme.palette.greens.green200,
      color: theme.palette.greens.green900,
    },
  },
  12: {
    label: 'Cancelled',
    styles: {
      backgroundColor: theme.palette.oranges.orange200,
      color: theme.palette.oranges.orange900,
    },
  },
};

export const ARNM_ACCIDENTS_TASK_STATUSES_SERVER_NAMES = {
  0: 'Backlog',
  1: 'Todo',
  2: 'InProgress',
  13: 'ServicePoint',
  14: 'OutDate',
  16: 'Documentation',
  17: 'ChargeClient',
  18: 'DocumentationInsuranceCompany',
  19: 'WaitingReceipt',
  20: 'MoreActions',
  21: 'InsurancePayment',
  3: 'Done',
  12: 'Cancelled',
};

export const ARNM_ACCIDENTS_LABEL_FIELD_NAMES = {
  2: '',
};

export const ARNM_REASONS_ACCIDENTS = [
  { text: 'Ατύχημα/Συμβάν', value: 'AC14' },
  { text: 'Κλοπή', value: 'ST15' },
  { text: 'Θραύση Κρυστάλλων', value: 'GB16' },
];

// TODO: update the custom fields
export const ARNM_ACCIDENTS_TASK_FIELD_NAMES = {
  chargeClientAmount: 'Charge Client Amount',
  outDateFromServicePoint: 'Out Date from Service Point',
  dateToServicePoint: 'Date to Service Point',
  emailSent: 'Email Sent',
  noChargesToClient: 'No Charges to Client',
  expectedOutFromServicePoint: 'Expected Out from Service Point',
  instadelivery: 'Instadelivery',
  invoiceDate: 'Invoice Date',
  noCostForInstacar: 'No cost for instacar',
  offer: 'Offer',
  dateOfRoadsideAssistance: 'Date of Roadside Assistance',
  servicePoint: 'Service Point',
  temporaryCarId: 'Temporary Car ID',
  doWeNeedATempCar: 'Do we need a Temp Car?',
  totalInvoiceCost: 'Total Invoice Cost',
  workOrder: 'Work Order',
  workorderid: 'Work Order ID',
  worktype: 'Work Type',
  details: 'Details',
  sideone: 'Side 1',
  sidetwo: 'Side 2',
  offeramount: 'Offer Amount',
  offerAmount: 'Offer Amount',
  chargeclientamount: 'Charge Client Amount',
  insuranceCompany: 'Insurance Company',
  carSwapCompletion: 'Car Swap Completion',
  concession: 'Concession',
  culpability: 'Culpability',
  docsSentToInsurance: 'Docs Sent to Insurance',
  documentationReceived: 'Documentation Received',
  exemption: 'Exemption',
  financeConfirmation: 'Finance Confirmation',
  insurance: 'Insurance',
  insuranceDate: 'Insurance Date',
  insuranceFolderNo: 'Insurance Folder Number',
  insuranceInspection: 'Insurance Inspection',
  insuranceReceiptAttached: 'Insurance Receipt Attached',
  noMoreActionsFromOPS: 'No More Actions from OPS',
  repairOnReturn: 'Repair on Return',
  totalInsuranceAmount: 'Total Insurance Amount',
  carSwapCompletition: 'Car Swap Completition',
  noMoreActionsNeededFromOPS: 'No More Actions Needed from OPS',
  status: 'Status',
  kilometers: 'Kilometers',
  assignee: 'Assignee',
};

export const ARNM_REASONS_ACCIDENTS_MAP = {
  OT01: 'Other',
  SE03: 'Service',
  MA04: 'Malfunction',
  TI05: 'Change tires',
  CH06: 'Vehicle Check',
  KT07: 'KTEO',
  KK08: 'Κάρτα Καυσαερίων',
  PA09: 'Parktronic',
  RR10: 'Roof Rack',
  TW11: 'Tinted Windows',
  AN12: 'Ανάκληση',
  AL13: 'Αλυσίδες',
  AC14: 'Ατύχημα/Συμβάν',
  ST15: 'Κλοπή',
  GB16: 'Θραύση Κρυστάλλων',
};
