/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import {
  selectAvailabilityTableState,
  getAvailability,
  getAvailabilityEnums,
  filtersModalChange,
  resetModalChange,
} from '@store';
import { showError, getCleanedUrlParams, getUrl } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';

export const useAvailabilityTableOperations = (fetchOnMount) => {
  const { loading, data, total, errorMessage, hasErrors, enums, filters } =
    useSelector(selectAvailabilityTableState);

  const dispatch = useDispatch();

  const { push, query, isReady } = useRouter();
  const { page = '1', orderBy = '', order = '', ...remainingQueries } = query;

  const remainingQueriesKeys = Object.keys(remainingQueries);

  const _getAvailability = useCallback(
    async (urlParams, modalFiltersRules) => {
      await dispatch(
        getAvailability({
          urlParams: getCleanedUrlParams(urlParams),
          modalFiltersRules,
        })
      );
    },
    [page, orderBy, order, dispatch, ...remainingQueriesKeys]
  );

  const _getAvailabilityEnums = useCallback(async () => {
    await dispatch(getAvailabilityEnums());
  }, [dispatch]);

  const _filtersModalChange = useCallback(
    async (conditions) => {
      if (!conditions?.rules?.length) {
        await dispatch(
          getAvailability({
            urlParams: getCleanedUrlParams(query),
            modalFiltersRules: filters.modalConditions?.rules?.length
              ? null
              : filters.modalFiltersRules,
          })
        );
      }
      dispatch(filtersModalChange(conditions));
    },
    [dispatch, query]
  );

  const _resetModalChange = useCallback(() => {
    dispatch(resetModalChange());
  }, [dispatch]);

  const _nextPage = useCallback(
    (currentPage) => {
      const params = {
        orderBy,
        order,
        page: currentPage,
        ...remainingQueries,
      };
      const url = getUrl(params, URLS.availability);

      push(url, undefined, { shallow: true });
    },
    [push]
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      const params = {
        ...sortingModel,
        page,
        ...remainingQueries,
      };

      const url = getUrl(params, URLS.availability);

      push(url, undefined, { shallow: true });
    },
    [push, page, orderBy, order]
  );

  const _initialize = async () => {
    await _getAvailability(query, filters.modalFiltersRules);
  };

  useEffect(() => {
    _getAvailabilityEnums();
  }, []);

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _initialize();
    }
  }, [isReady, query]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
    if (enums.errorMessage) {
      showError(enums.errorMessage);
    }
  }, [errorMessage, enums.errorMessage]);

  return {
    loading,
    total,
    data,
    hasErrors,
    errorMessage,
    page,
    enums,
    filters,
    getAvailability: _getAvailability,
    filtersModalChange: _filtersModalChange,
    nextPage: _nextPage,
    sortBy: _sortBy,
    resetModalChange: _resetModalChange,
  };
};
