/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectTrafficOffensesState,
  getTrafficOffenses,
  selectTrafficOffensesColumnsState,
} from '@store';
import { showError } from '@utils';
import { boardsServices } from '@services';
import { BOARDS, BOARDS_ID } from '@constants';

export const useTrafficOffensesOperations = (fetchOnMount) => {
  const {
    total,
    loading,
    data,
    boardId,
    pageSize,
    hasErrors,
    errorMessage,
    assignees,
  } = useSelector(selectTrafficOffensesState);
  const columns = useSelector(selectTrafficOffensesColumnsState);

  const {
    isReady,
    push,
    query: { page = 1, orderBy = 'createdAt', order = 'desc', token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getTrafficOffenses = useCallback(async () => {
    const defaultRule = {
      type: 5,
      field: 'boardId',
      operator: 0,
      value: BOARDS_ID.TRAFFIC_OFFENSES,
    };

    await dispatch(
      getTrafficOffenses({
        orderBy,
        order,
        page,
        pageSize,
        boardId: BOARDS_ID.TRAFFIC_OFFENSES,
        boardType: BOARDS.TRAFFIC_OFFENSES,
        ...defaultRule,
      })
    );
  }, [page, orderBy, order, dispatch]);

  const _addTask = useCallback(
    async (payload = {}) => {
      await boardsServices.addTask(BOARDS_ID.TRAFFIC_OFFENSES, { ...payload });
      await _getTrafficOffenses();
    },
    [boardId]
  );

  const _updateTask = useCallback(
    async (payload = {}) => {
      try {
        if (payload?.cardId) {
          const taskId = payload?.cardId;
          delete payload?.cardId;
          await boardsServices.updateTask(taskId, payload);
        } else {
          await boardsServices.updateTask(payload?.id, payload);
        }
      } catch (error) {
        showError(error);
      }
    },
    [data]
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/trafficOffenses?orderBy=${
        sortingModel?.orderBy || ''
      }&order=${sortingModel?.order || ''}&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/trafficOffenses?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/trafficOffenses?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/trafficOffenses?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    setTimeout(() => {
      if (fetchOnMount && isReady) {
        _getTrafficOffenses();
      }
    }, 100);
  }, [page, token, _getTrafficOffenses, fetchOnMount, isReady]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    pageSize,
    loading,
    data,
    columns,
    hasErrors,
    errorMessage,
    assignees,
    addTask: _addTask,
    updateTask: _updateTask,
    getTrafficOffenses: _getTrafficOffenses,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};

export default useTrafficOffensesOperations;
