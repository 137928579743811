import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBookingsState,
  getBookings,
  addSnapshot,
  clearSnapshot,
} from '@store';
import { showError } from '@utils';
import { useLayout } from 'hooks/layout';

export const useBookingsOperations = (fetchOnMount) => {
  const {
    total,
    pageSize,
    loading,
    bookings,
    hasErrors,
    errorMessage,
    bookingSnapshot,
  } = useSelector(selectBookingsState);
  const {
    push,
    query: { page = 1, orderBy = '', order = '', token, query = '' },
    isReady,
  } = useRouter();
  const { tenant } = useLayout();
  const dispatch = useDispatch();

  const _getBookings = useCallback(async () => {
    await dispatch(getBookings({ orderBy, order, page, query, tenant }));
  }, [orderBy, order, page, query, tenant, dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/bookings?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}&query=${query}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/bookings?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page, query]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/bookings?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}&query=${query}`;
      if (!orderBy || !order) {
        url = `/bookings?page=${currentPage}&query=${query}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, query, push]
  );

  const _addSnapshot = useCallback(
    async (payload) => {
      dispatch(addSnapshot(payload));
    },
    [dispatch]
  );

  const _clearSnapshot = useCallback(async () => {
    dispatch(clearSnapshot());
  }, [dispatch]);

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getBookings();
    }
  }, [_getBookings, token, fetchOnMount, isReady]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    pageSize,
    loading,
    bookings,
    hasErrors,
    errorMessage,
    token,
    bookingSnapshot,
    query,
    sortBy: _sortBy,
    getBookings: _getBookings,
    nextPage: _nextPage,
    addSnapshot: _addSnapshot,
    clearSnapshot: _clearSnapshot,
  };
};
