import { request } from '@utils';
import { BOARDS_ID, LIMIT } from '@constants';
import { orderBy } from 'lodash';

const getVehicles = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  const results = await request.get(`/v1/vehicles`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehiclesEnums = async () => {
  const results = await request.get(`/v1/vehicles/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehicleById = async (vehicleId) => {
  const results = await request.get(`/v1/vehicles/${vehicleId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehicleByInternalId = async (carId) => {
  const results = await request.get(`/v1/vehicles/internal/${carId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getInsurances = async () => {
  const results = await request.get(`/v1/insurances`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getRoadsideAssistances = async () => {
  const results = await request.get(`/v1/roadsideAssistances`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehicleConfigOptions = async (type) => {
  const results = await request.get(`/v1/vehicles/config/${type}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postVehicleTab = async (tab, vehicleId, data) => {
  const payload = {
    body: data,
  };

  const results = await request.post(
    `/v1/vehicles/${vehicleId}/${tab}`,
    payload
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const patchProcurement = async (vehicleId, data) => {
  const payload = {
    body: data,
  };

  const results = await request.patch(
    `/v1/vehicles/${vehicleId}/procurement`,
    payload
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postProcurementEvent = async (vehicleId, data, event) => {
  const payload = {
    body: data,
  };

  const results = await request.post(
    `/v1/vehicles/${vehicleId}/procurement/events/${event}`,
    payload
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateVehicleServicePoints = async (vehicleId, data) => {
  const payload = {
    body: data,
  };

  const results = await request.patch(
    `/v1/vehicles/${vehicleId}/servicePoints`,
    payload
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateVehicleImage = async (vehicleId, imageId, data) => {
  const payload = {
    body: data,
  };

  const results = await request.patch(
    `/v1/vehicles/${vehicleId}/image/${imageId}`,
    payload
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postVehicleImage = async (vehicleId, data) => {
  const payload = {
    body: data,
  };

  const results = await request.post(
    `/v1/vehicles/${vehicleId}/image`,
    payload
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehiclePlatesById = async (vehicleId) => {
  const results = await request.get(
    `/v1/vehicles/${vehicleId}/actions?type=PLATE_UPDATE`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehicleMileagesById = async (vehicleId) => {
  const results = await request.get(
    `/v1/vehicles/${vehicleId}/actions?type=MILEAGE_UPDATE`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postVehicleDocuments = async (vehicleId, data) => {
  const payload = {
    body: data,
    header: {
      Accept: '*/*',
    },
  };

  const results = await request.post(
    `/v1/vehicles/${vehicleId}/documents`,
    payload
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteVehicleDocument = async (vehicleId, fileId) => {
  const results = await request.remove(
    `/v1/vehicles/${vehicleId}/documents/${fileId}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editVehicleDocument = async (vehicleId, documentId, params) => {
  const payload = {
    body: params,
  };
  const results = await request.patch(
    `/v1/vehicles/${vehicleId}/documents/${documentId}`,
    payload
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehicleServicePointsByID = async ({ id }) => {
  const results = await request.get(`/v1/vehicles/${id}/servicePoints`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const patchVehicleServicePointsByID = async (vehicleId, data) => {
  const payload = {
    body: data,
  };

  const results = await request.patch(
    `/v1/vehicles/${vehicleId}/servicePoints`,
    payload
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addVehicle = async (params) => {
  const payload = {
    body: params,
  };
  const results = await request.post(`/v1/vehicles`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehicleColors = async () => {
  const results = await request.get(`/v1/vehicles/config/exterior-colors`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehicleDealers = async () => {
  const results = await request.get(`/v1/vehicles/config/dealers`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehicleSourcingTypes = async () => {
  const results = await request.get(`/v1/vehicles/config/sourcing-types`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehicleProviders = async () => {
  const results = await request.get(`/v1/vehicles/config/providers`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehiclesModels = async () => {
  const results = await request.get(`/v2/vehicles/config/models`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const exportFile = async (type) => {
  const results = await request.get(`/v1/vehicles/export`, {
    type,
    responseType: 'text',
  });

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const exportVehicleServicePoints = async (type) => {
  const results = await request.get(`/v1/vehicles/export/servicePoints`, {
    type,
    responseType: 'text',
  });

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVehicleHistory = async (carId) => {
  const getBody = (type) => ({
    type: 0,
    field: null,
    operator: null,
    value: [
      {
        type: 5,
        field: 'customFields.carId',
        operator: 0,
        value: carId,
      },
      {
        type: 5,
        field: 'boardId',
        operator: 0,
        value: type,
      },
    ],
  });

  const getResults = async (type) => {
    const res = await request.post(
      `/v1/boards/${type}/tasks/filter?page=1&pageSize=600`,
      {
        body: getBody(type),
      }
    );
    if (res?.error) {
      return Promise.reject(res?.message);
    }

    return res.data.map((item) => ({ ...item, type }));
  };

  const resultsAC = await getResults(BOARDS_ID.ARM_ACCIDENTS);
  const resultsRep = await getResults(BOARDS_ID.ARM_REPAIRS);
  const resultsMain = await getResults(BOARDS_ID.ARM_MAINTENANCE);

  const results = [...resultsAC, ...resultsRep, ...resultsMain];

  const sortedResults = orderBy(results, ['createdAt'], ['desc']);

  return sortedResults;
};

const getVehicleChangelog = async (id) => {
  const results = await request.get(
    `/v1/changelogs?entityID=${id}&orderBy=createdAt&order=desc&page=1&pageSize=500`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateVehicle = async (vehicleId, data) => {
  const payload = {
    body: data,
  };

  const results = await request.patch(`/v1/vehicles/${vehicleId}`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addVehicleSale = async (vehicleInternalId) => {
  const payload = {
    body: {
      vehicleInternalId,
    },
  };

  const results = await request.post(`/v1/vehicle-sales`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getVehicles,
  getVehicleById,
  getInsurances,
  getRoadsideAssistances,
  getVehicleConfigOptions,
  postVehicleTab,
  postProcurementEvent,
  getVehiclePlatesById,
  getVehicleMileagesById,
  updateVehicleImage,
  postVehicleImage,
  postVehicleDocuments,
  deleteVehicleDocument,
  getVehicleServicePointsByID,
  patchVehicleServicePointsByID,
  updateVehicleServicePoints,
  addVehicle,
  getVehicleColors,
  getVehicleDealers,
  getVehicleSourcingTypes,
  getVehicleProviders,
  getVehiclesModels,
  getVehiclesEnums,
  patchProcurement,
  exportFile,
  getVehicleByInternalId,
  getVehicleHistory,
  editVehicleDocument,
  updateVehicle,
  getVehicleChangelog,
  addVehicleSale,
  exportVehicleServicePoints,
};
