import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { agentsServices, reservationServices } from '@services';
import { GENERIC_ERROR_MESSAGE } from '@constants';
import {
  handleReservationData,
  handleReservationHistory,
} from './reservations.handlers';

export const RESERVATION_LIMIT = 200;

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  reservation: {},
  reservationsEnums: {},
  agents: [],
  total: 0,
  pageSize: RESERVATION_LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const reservationsSlice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    fetchReservation: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.reservation = {};
    },
    fetchReservationSuccess: (state, { payload }) => {
      state.reservation = handleReservationData(payload?.reservation?.data);
      state.reservationsEnums = payload?.enums?.data;
      state.agents = payload?.assignees?.data;
      state.history = handleReservationHistory(payload?.history?.data);
      state.total = payload?.reservation?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchReservationFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchReservation,
  fetchReservationSuccess,
  fetchReservationFailure,
  initializePostReservation,
} = reservationsSlice.actions;

export const selectReservationsState = (state) => state.reservations;

export const getReservation = (payload) => {
  return async (dispatch) => {
    dispatch(fetchReservation());

    try {
      const data = {};
      if (payload) {
        data.reservation = await reservationServices?.getReservationById(
          payload
        );
      } else {
        data.reservation = {};
      }
      data.history = await reservationServices?.getReservationsHistoryById(
        payload
      );
      data.enums = await reservationServices?.getReservationsEnums();
      data.assignees = await agentsServices.getAgents({
        page: 1,
        pageSize: 1000,
      });

      dispatch(fetchReservationSuccess(data));
    } catch (error) {
      dispatch(fetchReservationFailure(error));
    }
  };
};

export default reservationsSlice.reducer;
