export const handleProduct = (product) => {
  if (!product) return {};
  return {
    ...product,
    price: product?.price?.number ? `${product?.price?.number}` : null,
    pos: product?.pos?.length ? product?.pos?.join(', ') : '-',
    customFields: {
      ...product?.customFields,
      vehicleTypes: product?.customFields?.vehicleTypes
        ? product?.customFields?.vehicleTypes?.join(', ')
        : undefined,
      vehiclePrice: product?.customFields?.vehiclePrice
        ? product?.customFields?.vehiclePrice
        : undefined,
      deliveryPoint: product?.customFields?.deliveryPoint
        ? product?.customFields?.deliveryPoint
        : undefined,
      month: product?.customFields?.month
        ? product?.customFields?.month
        : undefined,
      durationInMonths: product?.customFields?.durationInMonths
        ? product?.customFields?.durationInMonths
        : undefined,
    },
  };
};
