import { PRICING_TYPES } from '@constants';
import { pricingServices } from '@services';
import { showError } from '@utils';
import { useState } from 'react';

export const useVehiclePricing = ({ vehicle, variant = 'vehicle' }) => {
  const [pricing, setPricing] = useState([]);

  const [isLoadingPrices, setIsLoadingPrices] = useState(false);
  const getPrices = () => {
    const { pricing: vehiclePricing } = vehicle[variant];
    setPricing(vehiclePricing);
  };

  const getUpdatedFixedPrices = async (currentFixedPrices, values) => {
    let updatedFixedData = [];
    const updatePrice = (obg) => {
      if (variant === 'ebike') {
        return pricingServices.getEbikesPricing({
          price: values?.price,
          duration: obg?.monthsDuration,
        });
      }
      return pricingServices.getPricing({
        price: values?.price,
        duration: obg?.monthsDuration,
      });
    };

    updatedFixedData = await Promise.all(currentFixedPrices.map(updatePrice));
    return updatedFixedData;
  };

  const handleUpdatePricing = async (values) => {
    let newPricing = [];
    let currentOpenPrice = pricing?.find((item) => {
      return item?.type === PRICING_TYPES?.open;
    });
    let currentFixedPrices = pricing?.filter((item) => {
      return item?.type === PRICING_TYPES?.fixed;
    });
    let updatedFixedData = [];
    if (
      values?.type === PRICING_TYPES.open &&
      values?.price &&
      Number(currentOpenPrice?.price) !== values?.price
    ) {
      try {
        setIsLoadingPrices(true);

        updatedFixedData = await getUpdatedFixedPrices(
          currentFixedPrices,
          values
        );
      } catch (error) {
        showError(error);
      } finally {
        setIsLoadingPrices(false);
      }
    }

    if (values?.type === PRICING_TYPES.open) {
      currentOpenPrice = {
        ...values,
        price: `${values.price || 0}`,
        signUpFee: `${values.signUpFee || ''}`,
        initialPrice: `${values.initialPrice || 0}`,
      };
    }
    currentFixedPrices = currentFixedPrices?.map((item, index) => {
      if (updatedFixedData?.length && updatedFixedData[index]) {
        return {
          ...item,
          price: updatedFixedData[index]?.data?.price,
        };
      }
      if (item?.id === values?.id) {
        return {
          ...values,
          price: `${values?.price || 0}`,
          signUpFee: `${values?.signUpFee || 0}`,
          initialPrice: `${
            item?.type === values?.type ? values.initialPrice || 0 : 0
          }`,
        };
      }
      return { ...item };
    });
    newPricing = [currentOpenPrice, ...currentFixedPrices];
    setPricing(newPricing);
  };

  return {
    getPrices,
    pricing,
    handleUpdatePricing,
    isLoadingPrices,
  };
};
