import { request } from '@utils';

const getPricing = async (payload = {}) => {
  const results = await request.get(`/v2/catalog/pricing`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getEbikesPricing = async (payload = {}) => {
  const results = await request.get(`/v1/catalog/ebikes/pricing`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { getPricing, getEbikesPricing };
