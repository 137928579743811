import dynamic from 'next/dynamic';

const SellRequestsGrid = dynamic(() =>
  import('./SellRequestsGrid/SellRequestsGrid')
);

const AddSellRequestsModal = dynamic(() =>
  import('./AddSellRequestsModal/AddSellRequestsModal')
);

const SellRequestsForm = dynamic(() =>
  import('./SellRequestsForm/SellRequestsForm')
);

const SellRequestsVehicleHeader = dynamic(() =>
  import('./SellRequestsVehicleHeader/SellRequestsVehicleHeader')
);

const ChangeSellRequestStage = dynamic(() =>
  import('./ChangeSellRequestStage/ChangeSellRequestStage')
);

const SellRequestsFilters = dynamic(() =>
  import('./SellRequestsFilters/SellRequestsFilters')
);

const SellRequestsListContent = dynamic(() =>
  import('./SellRequestsListContent')
);

export {
  SellRequestsGrid,
  AddSellRequestsModal,
  SellRequestsForm,
  SellRequestsVehicleHeader,
  ChangeSellRequestStage,
  SellRequestsFilters,
  SellRequestsListContent,
};
