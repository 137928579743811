/* eslint-disable no-console */
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';

import {
  agentsServices,
  boardsServices,
  fleetVehiclesServices,
  usersServices,
} from '@services';
import { asyncForEach, showError } from '@utils';
import { QUERY_KEYS } from 'constants/queryKeys';
import {
  getPayload,
  handleDefleetTaskData,
  normaliseAssigneeData,
  normalizeUserData,
  normalizeVehicleData,
} from './utils';
import { DEFLEET_TABS } from './constants';

export const useDefleetTaskOperations = () => {
  const router = useRouter();
  const defleetTaskId = router.query?.defleetTaskId;

  const assigneesResponse = useQuery(QUERY_KEYS.GET_DEFLEET_ASSIGNEES_KEY, () =>
    agentsServices.getAgents({
      page: 1,
      pageSize: 3000,
    })
  );

  const taskResponse = useQuery(
    [QUERY_KEYS.GET_DEFLEET_TASK_KEY, defleetTaskId],
    () => boardsServices.getTaskById(defleetTaskId),
    {
      enabled: assigneesResponse.isFetched,
    }
  );

  const vehicleResponse = useQuery(
    [QUERY_KEYS.GET_VEHICLE_BY_INTERNAL_ID_KEY, defleetTaskId],
    () =>
      fleetVehiclesServices.getVehicleByInternalId(
        taskResponse.data.data.customFields?.carId
      ),
    {
      enabled:
        taskResponse.isFetched &&
        !!taskResponse?.data?.data?.customFields?.carId,
    }
  );

  const userResponse = useQuery(
    [QUERY_KEYS.GET_DEFLEET_USER_KEY, defleetTaskId],
    () => usersServices.getUser(taskResponse.data.data.customFields?.userId),
    {
      enabled:
        taskResponse.isFetched &&
        !!taskResponse?.data?.data?.customFields?.userId,
    }
  );

  const assignees =
    normaliseAssigneeData(assigneesResponse?.data?.data || []) || [];

  const mentionedUsers = assignees?.data?.map?.((user) => {
    return { id: user?.value, display: user?.label };
  });

  const vehicle = normalizeVehicleData(vehicleResponse?.data?.data || {});
  const user = normalizeUserData(userResponse?.data?.data || {});
  const loading =
    taskResponse?.isLoading ||
    assigneesResponse?.isLoading ||
    vehicleResponse?.isLoading ||
    userResponse?.isLoading;

  const updateTask = async ({ values, images, documents }) => {
    try {
      const imagesToupLoad = images?.filter((img) => img?.localFile);
      const documentsToUpLoad = documents?.filter((doc) => doc?.localFile);

      const payload = getPayload(values);

      await boardsServices.updateTask(defleetTaskId, payload);

      await asyncForEach(imagesToupLoad, async (img) => {
        await boardsServices.uploadTaskImage(defleetTaskId, img?.file);
      });

      await asyncForEach(documentsToUpLoad, async (doc) => {
        await boardsServices.uploadTaskDocument(
          defleetTaskId,
          doc?.file,
          '',
          doc?.tags
        );
      });
    } catch (error) {
      showError(error);
    }
  };
  const boardId = defleetTaskId?.split('-')?.[0];
  const board =
    Object.keys(DEFLEET_TABS).find((key) => DEFLEET_TABS[key].id === boardId) ||
    {};

  const stages = DEFLEET_TABS[board]?.statusOrder?.reduce((acc, cur) => {
    acc.push({
      text: DEFLEET_TABS[board].statuses[cur].label,
      value: Number(cur),
      board,
    });
    return acc;
  }, []);

  return {
    refresh: taskResponse.refetch,
    assignees,
    mentionedUsers,
    loading,
    board,
    stages,
    error: taskResponse?.error,
    isSuccess: taskResponse?.isSuccess,
    task: handleDefleetTaskData({
      data: taskResponse?.data?.data || [],
      assignees: assignees || [],
      stages: stages || [],
    }),
    vehicle: loading ? {} : vehicle,
    user: loading ? {} : user,
    updateTask,
  };
};
