import { RESERVATIONS_TYPES } from '@constants';
import { formatDate } from '@utils';
import { ENTITIES_PERMISIONS } from 'components/common/PermissionsGate/constants';

const allowedPermissions = [
  ENTITIES_PERMISIONS.ReadBookings,
  ENTITIES_PERMISIONS.ReadReservations,
];

export const normalizeReservations = (data = []) => {
  if (!data.length > 0) {
    return [];
  }

  const reservations = data.map((item) => {
    return {
      ...item,
      agent: item?.agent || '-',
      comments: item?.comments
        ? item.comments.map((comment) => comment.message).join(', ')
        : '-',
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      createdBy: item?.createdBy || '-',
      subscriptionId: item?.subscriptionId || '-',
      subscriptionType: item?.subscriptionType || null,
      updatedAt: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY HH:mm')
        : '-',
      updatedBy: item?.updatedBy || '-',
      vehicleInternalId: item?.vehicleInternalId || '',
      vehicleName: item?.vehicleName || '',
      vehiclePlate: item?.vehiclePlate || '',
    };
  });

  return reservations;
};

export const normalizeReservationsEnums = (data = []) => {
  if (!data?.types?.length) {
    return [];
  }
  return data?.types.map((item) => {
    return {
      text: RESERVATIONS_TYPES?.[item.name]?.text || item.name,
      value: item.id,
    };
  });
};

export const normalizeReservationsAgents = (data = []) => {
  if (!data?.length) {
    return [];
  }
  return data
    .map((item) => {
      return {
        isActive: item?.isActive,
        text:
          item.firstName && item.lastName
            ? `${item?.firstName} ${item?.lastName}`
            : item.email,
        value: item.email,
        permissions: item.permissions,
      };
    })
    ?.sort((a, b) => a.text.localeCompare(b.text))
    ?.filter((agent) =>
      allowedPermissions.every((permission) =>
        agent.permissions.includes(permission)
      )
    );
};
