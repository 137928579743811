import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectVehiclesState, getVehicles } from '@store';
import { showError } from '@utils';
import { LIMIT } from '@constants';
import { getCatalogRequestPayload } from 'helpers/catalog/cars';

export const useVehiclesOperations = (fetchOnMount) => {
  const { total, loading, vehicles, hasErrors, errorMessage } =
    useSelector(selectVehiclesState);
  const {
    push,
    query: {
      page = 1,
      pageSize = LIMIT,
      orderBy = '',
      order = '',
      token,
      ...others
    },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _otherQueries = Object.entries(others)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const _getVehicles = useCallback(async () => {
    const modalFiltersRules =
      localStorage.marketVehiclesQuery &&
      JSON.parse(localStorage.marketVehiclesQuery);
    const payload = getCatalogRequestPayload(others, modalFiltersRules);

    await dispatch(
      getVehicles({ params: { orderBy, order, page, pageSize }, payload })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order, dispatch, _otherQueries]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/catalog/cars?page=${page}&${_otherQueries}`;
      if (sortingModel?.orderBy || sortingModel?.order) {
        url = `${url}&orderBy=${sortingModel?.orderBy || ''}&order=${
          sortingModel?.order || ''
        }`;
      }
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/catalog/cars?page=${page}&${_otherQueries}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page, _otherQueries]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/catalog/cars?&page=${currentPage}&${_otherQueries}`;

      if (orderBy || order) {
        url = `${url}&orderBy=${orderBy || ''}&order=${order || ''}`;
      }
      if (!Object.keys(others).length && (!orderBy || !order)) {
        url = `/catalog/cars?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push, _otherQueries, others]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getVehicles();
    }
  }, [_getVehicles, token, fetchOnMount, isReady, _otherQueries]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    pageSize,
    total,
    loading,
    vehicles,
    hasErrors,
    errorMessage,
    getVehicles: _getVehicles,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
