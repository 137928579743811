import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { ordersServices } from '@services';
import { showError } from '@utils';
import { getOrdersRequestPayload, getOrdersRequestQueryParams } from '@helpers';
import { LIMIT } from '@constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import { handleOrders } from './utils';

export const useOrdersOperations = () => {
  const {
    push,
    query: {
      page = 1,
      pageSize = LIMIT,
      orderBy = 'createdAt',
      order = 'desc',
      status = null,
      token,
      ...others
    },
    isReady,
  } = useRouter();

  const _otherQueries = Object.entries(others)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const ordersResponse = useQuery(
    [QUERY_KEYS.GET_ORDERS_KEY, page, orderBy, order, status],
    () => {
      const payload = getOrdersRequestPayload({
        page,
        orderBy,
        order,
        status,
      });

      return ordersServices?.getOrdersFilters({
        urlParams: getOrdersRequestQueryParams({
          page,
          orderBy,
          order,
          status,
        }),
        payload,
      });
    },
    { enabled: isReady }
  );

  const orders = handleOrders(ordersResponse?.data?.data || []);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/orders?page=${page}&${_otherQueries}`;
      if (sortingModel?.orderBy || sortingModel?.order) {
        url = `${url}&orderBy=${sortingModel?.orderBy || ''}&order=${
          sortingModel?.order || ''
        }`;
      }
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/orders?page=${page}&${_otherQueries}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page, _otherQueries]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/orders?&page=${currentPage}&${_otherQueries}`;

      if (orderBy || order) {
        url = `${url}&orderBy=${orderBy || ''}&order=${order || ''}`;
      }
      if (!Object.keys(others).length && (!orderBy || !order)) {
        url = `/orders?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push, _otherQueries, others]
  );

  useEffect(() => {
    if (ordersResponse?.error) {
      showError(ordersResponse?.error);
    }
  }, [ordersResponse?.error]);

  return {
    page,
    total: ordersResponse?.data?.meta?.total,
    loading: ordersResponse?.isLoading,
    orders,
    pageSize,
    getOrders: ordersResponse.refetch,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
