import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getArnmMaintenanceTask, selectArnmMaintenanceState } from '@store';
import { showError } from '@utils';

export const useArnmMaintenanceTaskOperations = () => {
  const {
    arnmMaintenanceTask,
    vehicle,
    loading,
    hasErrors,
    errorMessage,
    assignees,
    servicePoints,
  } = useSelector(selectArnmMaintenanceState);
  const {
    query: { arnmMaintenanceTaskId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getArnmMaintenanceTask = useCallback(async () => {
    await dispatch(getArnmMaintenanceTask(arnmMaintenanceTaskId));
  }, [arnmMaintenanceTaskId, dispatch]);

  useEffect(() => {
    if (arnmMaintenanceTaskId !== undefined) {
      _getArnmMaintenanceTask();
    }
  }, [arnmMaintenanceTaskId, _getArnmMaintenanceTask, token]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    task: arnmMaintenanceTask,
    taskId: arnmMaintenanceTaskId,
    hasErrors,
    errorMessage,
    getArnmMaintenanceTask: _getArnmMaintenanceTask,
    token,
    assignees,
    servicePoints,
    vehicle,
  };
};
