import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getBuyOutTask, selectBuyOutState } from '@store';
import { showError } from '@utils';

export const useBuyOutTaskOperations = () => {
  const { buyOutTask, vehicle, loading, hasErrors, errorMessage } =
    useSelector(selectBuyOutState);
  const {
    query: { buyoutTaskId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getBuyOutTask = useCallback(async () => {
    await dispatch(getBuyOutTask(buyoutTaskId));
  }, [buyoutTaskId, dispatch]);

  useEffect(() => {
    if (buyoutTaskId !== undefined) {
      _getBuyOutTask();
    }
  }, [buyoutTaskId, _getBuyOutTask, token]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    vehicle,
    task: buyOutTask,
    taskId: buyoutTaskId,
    hasErrors,
    errorMessage,
    getBuyOutTask: _getBuyOutTask,
    token,
  };
};
