import dynamic from 'next/dynamic';

const PermissionsGrid = dynamic(() =>
  import('./PermissionsGrid/PermissionsGrid')
);
const AddPermissionModal = dynamic(() =>
  import('./AddPermissionModal/AddPermissionModal')
);
const PermissionsFilter = dynamic(() =>
  import('./PermissionsFilter/PermissionsFilter')
);

export { PermissionsGrid, AddPermissionModal, PermissionsFilter };
