import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTrafficOffensesTask,
  selectTrafficOffensesTaskState,
  resetTrafficOffensesTask,
} from '@store';
import { showError } from '@utils';
import { boardsServices } from '@services';

export const useTrafficOffensesTaskOperations = () => {
  const { task, vehicle, loading, hasErrors, errorMessage, assignees } =
    useSelector(selectTrafficOffensesTaskState);
  const {
    query: { trafficOffensesTaskId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getTrafficOffensesTask = useCallback(async () => {
    await dispatch(getTrafficOffensesTask(trafficOffensesTaskId));
  }, [trafficOffensesTaskId, dispatch]);

  const _resetTask = useCallback(async () => {
    dispatch(resetTrafficOffensesTask());
  }, [dispatch]);

  const _updateTask = useCallback(async (payload = {}) => {
    try {
      if (payload?.cardId) {
        const taskId = payload?.cardId;
        delete payload?.cardId;
        await boardsServices.updateTask(taskId, payload);
      } else {
        await boardsServices.updateTask(payload?.id, payload);
      }
    } catch (error) {
      showError(error);
    }
  }, []);

  useEffect(() => {
    if (trafficOffensesTaskId !== undefined) {
      _getTrafficOffensesTask();
    }
  }, [trafficOffensesTaskId, _getTrafficOffensesTask, token]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    task,
    taskId: trafficOffensesTaskId,
    hasErrors,
    errorMessage,
    getTrafficOffensesTask: _getTrafficOffensesTask,
    updateTask: _updateTask,
    resetTask: _resetTask,
    token,
    vehicle,
    assignees,
  };
};

export default useTrafficOffensesTaskOperations;
