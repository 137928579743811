import { BOARDS_ID } from '@constants';
import { formatDate, sortArray } from '@utils';
import { ARNM_REASONS_ACCIDENTS_MAP } from 'components/arnmAccidents/constants';

export const handleArnmAccidentsData = (data, assignees) => {
  if (!data?.length) {
    return {};
  }
  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.email] = {
      ...cur,
      shortName: `${cur.firstName?.[0] || ''}${
        cur.lastName?.[0] || ''
      }`.toUpperCase(),
    };
    return acc;
  }, {});
  const tasks = [];
  data?.forEach((item) => {
    const assignee = assigneesMap[item.assignee] || {};
    const newTasks = {
      ...item,
      href: `/arnmAccidents/${item?.id}`,
      status: item?.status,
      description: item?.name,
      assignee,
      date: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : null,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      // TODO: handle custom fields
      id: item?.id,
      servicePoint: item?.customFields?.servicePoint || '-',
      reason: ARNM_REASONS_ACCIDENTS_MAP[item?.customFields?.reason] || '-',
      outDateFromServicePoint: item?.customFields?.outDateFromServicePoint
        ? formatDate(item?.customFields?.outDateFromServicePoint, 'DD/MM/YYYY')
        : '-',
    };
    tasks.push(newTasks);
  });

  return {
    boardId: BOARDS_ID.ARM_ACCIDENTS,
    tasks,
  };
};

export const handleArnmAccidentsTaskData = (data) => {
  if (!data) {
    return {};
  }
  const task = {
    ...data,
    changelog: data?.changelog?.reverse(),
    comments: sortArray(data?.comments, 'createdAt', 'desc')?.map((entry) => {
      return {
        id: entry?.id,
        date: entry?.createdAt
          ? formatDate(entry?.createdAt, 'DD MMMM YYYY')
          : '-',
        time: entry?.createdAt ? formatDate(entry?.createdAt, 'HH:mm') : '-',
        name: entry?.userFullName || entry?.user || '-',
        comment: entry?.comment,
        edit: entry?.editedAt
          ? formatDate(entry?.editedAt, 'DD MMMM YYYY HH:mm')
          : '',
      };
    }),
    documents: data?.documents,
  };
  return task;
};

export const handleVehicleData = (data) => {
  if (!data) {
    return {};
  }
  const vehicle = {
    images: data?.images,
    title: `${data?.sku?.brand || ''} ${data?.sku?.model || ''} ${
      data?.sku?.edition || ''
    } ${data?.properties?.color || ''} ${data?.year || ''} - ${
      data?.plate || ''
    }`,
    subTitle: `${data?.internalId ? `${data?.internalId} -` : '' || ''} ${
      data?.vin ? `${data?.vin} -` : ''
    } ${
      data?.procurement?.insuranceProvider
        ? `${data?.procurement?.insuranceProvider} -`
        : ''
    } ${
      data?.properties?.transmission
        ? `${data?.properties?.transmission} -`
        : ''
    } ${data?.properties?.fuelType}`,
    ownershipInfo: `${
      data?.procurement?.vehicleOwnership
        ? `${data?.procurement?.vehicleOwnership} -`
        : ''
    } ${data?.procurement?.financingProvider || ''}`,
  };

  return vehicle;
};

export const handleAssigneeData = (data = []) =>
  data
    ?.sort((a, b) => a.firstName.localeCompare(b.firstName))
    ?.map((item) => ({
      isActive: item?.isActive,
      value: item.email,
      label:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
    }));
