import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { getUrl, showError } from '@utils';
import { permissionsServices } from '@services';
import { AGENT_ROLES, LIMIT } from '@constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import { rolesOptions } from 'components/agents/rolesOptions';

import { URLS } from 'layouts/DrawerLayout/constants';
import { handlePermissionsData } from './utils';

export const usePermissionsOperations = () => {
  const router = useRouter();
  const page = router.query?.page || '1';
  const role = router.query?.role || AGENT_ROLES.isAdmin;

  const permissionsResponse = useQuery(
    [QUERY_KEYS.GET_PERMISSIONS_KEY, { page, role }],
    () =>
      permissionsServices.getPermissions({
        page,
        pageSize: LIMIT,
        role,
      })
  );

  useEffect(() => {
    if (permissionsResponse?.error) {
      showError(permissionsResponse?.error);
    }
  }, [permissionsResponse?.error]);

  const handlePageChange = (_event, newPage) => {
    router.push(getUrl({ ...router.query, page: newPage }, URLS.permissions));
  };
  const roles = rolesOptions?.map((item) => {
    return {
      text: item?.label,
      value: item?.value,
    };
  });

  return {
    page,
    permissions: handlePermissionsData(permissionsResponse?.data?.data),
    pageSize: LIMIT,
    total: permissionsResponse?.data?.meta?.total,
    loading: permissionsResponse?.isLoading,
    error: permissionsResponse?.error,
    roles,
    role,
    getPermissions: permissionsResponse.refetch,
    handlePageChange,
  };
};
